import Log from '~/nasa_ui/modules/Log';
export class RouterLogUtility {
    options;
    router;
    routes = [];
    exactMatch = false;
    constructor(vueRouter, routes, exactMatch = false) {
        this.options = {
            mode: 'history',
            base: '/',
            scrollBehavior(to, from, savedPosition) {
                return { x: 0, y: 0 };
            },
            routes
        };
        this.router = new vueRouter(this.options);
        this.exactMatch = exactMatch;
        this.init();
    }
    init() {
        this.router.beforeEach((to, from, next) => {
            if (to.path === from.path && to.hash !== from.hash) {
                return next();
            }
            const publicPath = process.env.BASE_URL;
            const log = new Log();
            const fromUrl = from.fullPath === '/' ? publicPath : from.fullPath;
            const matchedPattern = this.exactMatch
                ? window.location.pathname === publicPath || window.location.pathname + '/' === publicPath
                : window.location.pathname.includes(publicPath);
            if (matchedPattern) {
                log.telemetry({
                    fromUrl: fromUrl,
                    toUrl: to.fullPath
                });
            }
            next();
        });
        return this.router;
    }
}

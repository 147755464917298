var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "container pa-1 mt-2"
  }, [_c('SearchInputContainer', {
    attrs: {
      "autofocus": _vm.autofocus,
      "disable-search": _vm.disableSearch,
      "entity-type": _vm.EntityType.INVENTORY,
      "hint": _vm.hint,
      "is-loading": _vm.isLoading,
      "placeholder": _vm.placeholder
    },
    on: {
      "searchButtonClicked": _vm.onClickOfSearchButton,
      "enterPressed": _vm.onClickOfSearchButton
    },
    model: {
      value: _vm.searchInput,
      callback: function ($$v) {
        _vm.searchInput = $$v;
      },
      expression: "searchInput"
    }
  }), _c('SearchFilterDisplay', {
    attrs: {
      "active": _vm.shouldShowFilters,
      "color": _vm.EntityType.INVENTORY,
      "entity-type": _vm.EntityType.INVENTORY,
      "chipsFromFilters": _vm.chipsFromFilters,
      "show-filter-button": _vm.showFilterButton
    },
    on: {
      "appChipCloseClicked": _vm.onClickOfRemoveFilter,
      "clearAllFilters": _vm.onClearAllFilters,
      "filterButtonClicked": _vm.onClickOfFilterIcon
    }
  }), _c('SearchFilterContainer', {
    attrs: {
      "color": _vm.EntityType.INVENTORY,
      "show": _vm.shouldShowFilters
    }
  }, [_c('v-layout', {
    attrs: {
      "row": "",
      "wrap": ""
    }
  }, [_c('v-flex', {
    attrs: {
      "xs12": "",
      "sm6": "",
      "pr-3": ""
    }
  }, [_c('AppSelectDrawingNumber', {
    attrs: {
      "disabled": _vm.isFilterLocked('drawingNumber'),
      "multiple": ""
    },
    model: {
      value: _vm.searchFilters.drawingNumber,
      callback: function ($$v) {
        _vm.$set(_vm.searchFilters, "drawingNumber", $$v);
      },
      expression: "searchFilters.drawingNumber"
    }
  })], 1), _c('v-flex', {
    attrs: {
      "xs12": "",
      "sm6": ""
    }
  }, [_c('AppSelectAsBuilt', {
    attrs: {
      "disabled": _vm.isFilterLocked('asBuiltNumber'),
      "multiple": ""
    },
    model: {
      value: _vm.searchFilters.asBuiltNumber,
      callback: function ($$v) {
        _vm.$set(_vm.searchFilters, "asBuiltNumber", $$v);
      },
      expression: "searchFilters.asBuiltNumber"
    }
  })], 1)], 1), _c('v-layout', {
    attrs: {
      "row": "",
      "wrap": ""
    }
  }, [_c('v-flex', {
    attrs: {
      "xs12": "",
      "sm6": "",
      "pr-3": ""
    }
  }, [_c('AppSelectSerial', {
    attrs: {
      "disabled": _vm.isFilterLocked('serialNumber'),
      "multiple": ""
    },
    model: {
      value: _vm.searchFilters.serialNumber,
      callback: function ($$v) {
        _vm.$set(_vm.searchFilters, "serialNumber", $$v);
      },
      expression: "searchFilters.serialNumber"
    }
  })], 1), _c('v-flex', {
    attrs: {
      "xs12": "",
      "sm6": ""
    }
  }, [_c('AppSelectLot', {
    attrs: {
      "disabled": _vm.isFilterLocked('lotNumber'),
      "multiple": ""
    },
    model: {
      value: _vm.searchFilters.lotNumber,
      callback: function ($$v) {
        _vm.$set(_vm.searchFilters, "lotNumber", $$v);
      },
      expression: "searchFilters.lotNumber"
    }
  })], 1)], 1), _c('v-layout', {
    attrs: {
      "row": "",
      "wrap": ""
    }
  }, [_c('v-flex', {
    attrs: {
      "xs10": "",
      "sm6": "",
      "pr-3": ""
    }
  }, [_c('AppSelectEnum', {
    attrs: {
      "color": _vm.EntityType.INVENTORY,
      "disabled": _vm.isFilterLocked('side'),
      "display": _vm.SideDisplay,
      "enum": _vm.Side,
      "label": "Side",
      "multiple": "",
      "sort": ""
    },
    model: {
      value: _vm.searchFilters.side,
      callback: function ($$v) {
        _vm.$set(_vm.searchFilters, "side", $$v);
      },
      expression: "searchFilters.side"
    }
  })], 1), _c('v-flex', {
    attrs: {
      "xs12": "",
      "sm6": ""
    }
  }, [_c('AppSelectProjectCode', {
    attrs: {
      "allow-create": false,
      "clearable": "",
      "disabled": _vm.isFilterLocked('projectCode'),
      "multiple": ""
    },
    model: {
      value: _vm.searchFilters.projectCode,
      callback: function ($$v) {
        _vm.$set(_vm.searchFilters, "projectCode", $$v);
      },
      expression: "searchFilters.projectCode"
    }
  })], 1), !_vm.searchFilters.crossContext ? _c('v-flex', {
    attrs: {
      "xs10": "",
      "sm6": "",
      "pr-3": ""
    }
  }, [_c('AppSelectEnum', {
    attrs: {
      "disabled": _vm.isFilterLocked('status'),
      "display": _vm.InventoryStatusDisplay,
      "enum": _vm.InventoryStatus,
      "label": "Status",
      "multiple": "",
      "sort": ""
    },
    model: {
      value: _vm.searchFilters.status,
      callback: function ($$v) {
        _vm.$set(_vm.searchFilters, "status", $$v);
      },
      expression: "searchFilters.status"
    }
  })], 1) : _vm._e(), _c('v-flex', {
    attrs: {
      "xs10": "",
      "sm6": ""
    }
  }, [_c('AppSelectEnum', {
    attrs: {
      "disabled": _vm.isFilterLocked('subType'),
      "display": _vm.ItemInstanceInventoryTypeDisplay,
      "enum": _vm.ItemInstanceInventoryTypeWithoutMultiple,
      "label": "Class",
      "multiple": "",
      "sort": ""
    },
    model: {
      value: _vm.searchFilters.subType,
      callback: function ($$v) {
        _vm.$set(_vm.searchFilters, "subType", $$v);
      },
      expression: "searchFilters.subType"
    }
  })], 1)], 1), _c('v-layout', {
    staticClass: "mb-4",
    attrs: {
      "row": "",
      "wrap": ""
    }
  }, [_c('v-flex', {
    attrs: {
      "xs12": "",
      "sm3": ""
    }
  }, [_c('AppCheckbox', {
    attrs: {
      "disabled": _vm.isFilterLocked('hasChildren'),
      "label": "Has Children"
    },
    model: {
      value: _vm.searchFilters.hasChildren,
      callback: function ($$v) {
        _vm.$set(_vm.searchFilters, "hasChildren", $$v);
      },
      expression: "searchFilters.hasChildren"
    }
  })], 1), _c('v-flex', {
    attrs: {
      "xs12": "",
      "sm3": ""
    }
  }, [_c('AppCheckbox', {
    attrs: {
      "disabled": _vm.isFilterLocked('hasQuantity'),
      "label": "Has Qty"
    },
    model: {
      value: _vm.searchFilters.hasQuantity,
      callback: function ($$v) {
        _vm.$set(_vm.searchFilters, "hasQuantity", $$v);
      },
      expression: "searchFilters.hasQuantity"
    }
  })], 1), _c('v-flex', {
    attrs: {
      "xs12": "",
      "sm3": ""
    }
  }, [_c('AppCheckbox', {
    attrs: {
      "disabled": _vm.isFilterLocked('includeArchived'),
      "label": "Include Archived"
    },
    model: {
      value: _vm.searchFilters.includeArchived,
      callback: function ($$v) {
        _vm.$set(_vm.searchFilters, "includeArchived", $$v);
      },
      expression: "searchFilters.includeArchived"
    }
  })], 1), _c('v-flex', {
    attrs: {
      "xs12": "",
      "sm3": ""
    }
  }, [_c('AppCheckbox', {
    attrs: {
      "disabled": _vm.isFilterLocked('isInstalled'),
      "label": "Is Installed"
    },
    model: {
      value: _vm.searchFilters.isInstalled,
      callback: function ($$v) {
        _vm.$set(_vm.searchFilters, "isInstalled", $$v);
      },
      expression: "searchFilters.isInstalled"
    }
  })], 1), !_vm.hideShowOtherContextFilter ? _c('v-flex', {
    attrs: {
      "xs12": ""
    }
  }, [_c('AppCheckbox', {
    attrs: {
      "disabled": _vm.isFilterLocked('crossContext'),
      "label": "Show other Contexts' Inventory"
    },
    model: {
      value: _vm.searchFilters.crossContext,
      callback: function ($$v) {
        _vm.$set(_vm.searchFilters, "crossContext", $$v);
      },
      expression: "searchFilters.crossContext"
    }
  })], 1) : _vm._e()], 1), _c('v-layout', {
    attrs: {
      "row": "",
      "wrap": ""
    }
  }, [_c('v-flex', {
    attrs: {
      "xs12": "",
      "sm6": "",
      "md3": "",
      "pr-2": ""
    }
  }, [_c('AppSelectBuilding', {
    attrs: {
      "disabled": _vm.isFilterLocked('building'),
      "multiple": ""
    },
    model: {
      value: _vm.searchFilters.building,
      callback: function ($$v) {
        _vm.$set(_vm.searchFilters, "building", $$v);
      },
      expression: "searchFilters.building"
    }
  })], 1), _c('v-flex', {
    attrs: {
      "xs12": "",
      "sm6": "",
      "md3": "",
      "pr-2": ""
    }
  }, [_c('AppSelectRoom', {
    attrs: {
      "disabled": _vm.isFilterLocked('room'),
      "multiple": ""
    },
    model: {
      value: _vm.searchFilters.room,
      callback: function ($$v) {
        _vm.$set(_vm.searchFilters, "room", $$v);
      },
      expression: "searchFilters.room"
    }
  })], 1), _c('v-flex', {
    attrs: {
      "xs12": "",
      "sm6": "",
      "md3": "",
      "pr-2": ""
    }
  }, [_c('AppSelectStock', {
    attrs: {
      "disabled": _vm.isFilterLocked('stock'),
      "multiple": ""
    },
    model: {
      value: _vm.searchFilters.stock,
      callback: function ($$v) {
        _vm.$set(_vm.searchFilters, "stock", $$v);
      },
      expression: "searchFilters.stock"
    }
  })], 1), _c('v-flex', {
    attrs: {
      "xs12": "",
      "sm6": "",
      "md3": "",
      "pr-2": ""
    }
  }, [_c('AppSelectBin', {
    attrs: {
      "disabled": _vm.isFilterLocked('bin'),
      "multiple": ""
    },
    model: {
      value: _vm.searchFilters.bin,
      callback: function ($$v) {
        _vm.$set(_vm.searchFilters, "bin", $$v);
      },
      expression: "searchFilters.bin"
    }
  })], 1)], 1), _c('v-layout', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.showMaxResults,
      expression: "showMaxResults"
    }],
    attrs: {
      "row": "",
      "wrap": ""
    }
  }, [_c('v-flex', {
    attrs: {
      "xs10": "",
      "sm6": "",
      "md3": "",
      "pr-4": ""
    }
  }, [_c('AppNumber', {
    attrs: {
      "label": "Max results",
      "wholeNumber": "",
      "max": 1000,
      "min": 0
    },
    model: {
      value: _vm.userSelectedMaxResults,
      callback: function ($$v) {
        _vm.userSelectedMaxResults = $$v;
      },
      expression: "userSelectedMaxResults"
    }
  })], 1)], 1)], 1), _c('v-fade-transition', {
    attrs: {
      "hide-on-leave": ""
    }
  }, [_c('AppLoader', {
    staticClass: "ma-0",
    attrs: {
      "color": _vm.EntityType.ITEM_INSTANCE,
      "show": _vm.isLoading,
      "type": "linear"
    }
  })], 1), _c('v-slide-y-transition', {
    attrs: {
      "tag": "table",
      "hide-on-leave": ""
    }
  }, [_vm.shouldShowTable && !_vm.searchFilters.crossContext ? _c('AppTable', {
    key: "searchResults",
    attrs: {
      "color": _vm.EntityType.INVENTORY,
      "headers": _vm.InventoryTableHeaders,
      "items": _vm.searchResults,
      "rows-per-page-items": _vm.defaultHardwareTablePagination,
      "show-count": false,
      "title": _vm.resultTableTitle,
      "dense-rows": "",
      "selectable-rows": "",
      "sticky": ""
    },
    on: {
      "metaRowClicked": _vm.onMetaClickOfSearchResult,
      "rowClicked": _vm.onClickOfSearchResult
    }
  }) : _vm.shouldShowTable && _vm.searchFilters.crossContext ? _c('AppTable', {
    key: "searchResults",
    attrs: {
      "color": _vm.EntityType.INVENTORY,
      "headers": _vm.crossContextInventoryTableHeaders,
      "items": _vm.searchResults,
      "rows-per-page-items": _vm.defaultHardwareTablePagination,
      "dense-rows": "",
      "selectable-rows": "",
      "show-count": "",
      "sticky": "",
      "title": "Results"
    },
    on: {
      "metaRowClicked": _vm.onMetaClickOfSearchResult,
      "rowClicked": _vm.onClickOfSearchResult
    }
  }) : _vm._e()], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };
import { __decorate } from "tslib";
import gql from 'graphql-tag';
import { groupBy, shuffle, uniqBy } from 'lodash';
import { Component } from 'vue-property-decorator';
import { ChangeSetStatus, ChangeSetType } from '~/db_types';
import BaseView from '~/nasa_ui/base/BaseView';
import { BASE_NUMBER_COL, BASE_TEXT_COL, CLOSED_DATE_COL, CREATED_BY_COL, CREATED_DATE_TIME_COL, INITIALIZATION_STATUS_ICON_COL, INITIATOR_CONTACT_COL, NUMBER_NO_UNDERSCORE_COL, OPENED_DATE_COL, STATUS_ICON_COL, SUB_TYPE_COL, TITLE_COL } from '~/nasa_ui/constants/genericColumnDefinitions';
import { CosmicContactSlimFragment } from '~/nasa_ui/DAL/contact/slim';
import { CosmicDocumentSlimFragment } from '~/nasa_ui/DAL/document/slim';
import { CosmicUserSlimFragment } from '~/nasa_ui/DAL/user/slim';
import { AlertType, CosmicUserSupportContext } from '~/nasa_ui/types';
import { DocumentTypeDisplay } from '~/nasa_ui/types/enums/document';
import { composeTableHeader } from '~/nasa_ui/utils/helpers/composeTableHeader';
import { transformChangeSets, transformDocuments } from '~/nasa_ui/utils/helpers/transformEntityToTableFormat';
const UserSupportContextToDocumentTableHeaders = new Map([
    [
        CosmicUserSupportContext.CTSD,
        [
            NUMBER_NO_UNDERSCORE_COL,
            SUB_TYPE_COL,
            CREATED_DATE_TIME_COL,
            CREATED_BY_COL,
            INITIATOR_CONTACT_COL,
            INITIALIZATION_STATUS_ICON_COL,
            composeTableHeader(STATUS_ICON_COL, {
                value: '_computedStatusIcon'
            }),
            OPENED_DATE_COL,
            CLOSED_DATE_COL,
            TITLE_COL
        ]
    ],
    [
        CosmicUserSupportContext.ESOC,
        [
            NUMBER_NO_UNDERSCORE_COL,
            SUB_TYPE_COL,
            CREATED_DATE_TIME_COL,
            CREATED_BY_COL,
            composeTableHeader(BASE_TEXT_COL, {
                text: 'Step / Task<br/>overview',
                value: '_stepOverview'
            }),
            OPENED_DATE_COL,
            CLOSED_DATE_COL,
            TITLE_COL
        ]
    ],
    [
        CosmicUserSupportContext.xEMU,
        [
            NUMBER_NO_UNDERSCORE_COL,
            SUB_TYPE_COL,
            CREATED_DATE_TIME_COL,
            CREATED_BY_COL,
            INITIATOR_CONTACT_COL,
            INITIALIZATION_STATUS_ICON_COL,
            composeTableHeader(STATUS_ICON_COL, {
                value: '_computedStatusIcon'
            }),
            OPENED_DATE_COL,
            CLOSED_DATE_COL,
            TITLE_COL
        ]
    ]
]);
let HomeView = class HomeView extends BaseView {
    activeDonutLabel = null;
    changeSetsQueryResults = null;
    homeViewDocumentsQueryResults = null;
    barChartOptions = {
        responsive: false,
        indexAxis: 'y',
        plugins: {
            legend: {
                display: false
            },
            datalabels: {
                display: false
            }
        },
        scales: {
            x: {
                stacked: true,
                ticks: {
                    stepSize: 1,
                    font: {
                        size: 8
                    }
                }
            },
            y: {
                stacked: true,
                ticks: {
                    display: false
                }
            }
        }
    };
    changeSetTableHeaders = [
        composeTableHeader(BASE_NUMBER_COL, {
            text: 'Document<br/>number',
            value: '_number',
            noWrap: true,
            width: '150px'
        }),
        composeTableHeader(SUB_TYPE_COL, {
            text: 'Document<br/>type',
            value: '_docSubType'
        }),
        TITLE_COL,
        CREATED_DATE_TIME_COL,
        composeTableHeader(INITIALIZATION_STATUS_ICON_COL, {
            value: '_status'
        }),
        OPENED_DATE_COL
    ];
    chartOptions = {
        responsive: true,
        aspectRatio: 4,
        cutout: '10%',
        plugins: {
            legend: {
                display: true
            }
        },
        onClick: (e, activeEls) => {
            if (!activeEls || activeEls.length === 0) {
                return;
            }
            const datasetIndex = activeEls[0].index;
            const clickedSectionLabel = e.chart.data.labels[datasetIndex];
            // activeEls[0].element.outerRadius += 10;
            this.setActiveDonutLabel(clickedSectionLabel);
        }
    };
    customPagination = {
        descending: true,
        rowsPerPage: 20,
        totalItems: 100
    };
    get chartData() {
        return {
            labels: uniqBy(this.homeViewDocuments, '_subType').map((doc) => doc._subType),
            datasets: [
                {
                    label: 'Total',
                    data: Object.entries(groupBy(this.homeViewDocuments, '_subType')).map((x) => {
                        return x[1].length;
                    }),
                    backgroundColor: shuffle([
                        '#EC407A',
                        '#AB47BC',
                        '#7E57C2',
                        '#5C6BC0',
                        '#42A5F5',
                        '#29B6F6',
                        '#26C6DA',
                        '#26A69A',
                        '#66BB6A',
                        '#9CCC65',
                        '#D4E157',
                        '#FFEE58',
                        '#FFA726',
                        '#FF7043',
                        '#78909C' // blueGrey.lighten-1
                    ])
                }
            ]
        };
    }
    get computedCosmicManagedDocumentTableHeaders() {
        return UserSupportContextToDocumentTableHeaders.get(this.currentUserActiveSupportContext);
    }
    get computedTableTitle() {
        if (this.activeDonutLabel) {
            return this.activeDonutLabel;
        }
        const validSubTypesForThisContext = this.currentUserActiveSupportContextValidDocumentTypes;
        return validSubTypesForThisContext?.map((docType) => `${DocumentTypeDisplay.get(docType)}`).join(', ');
    }
    get changeSetApprovalFilters() {
        return {
            groupCode: {
                in: (this.currentUserGroups?.map((group) => group?.code) || [])
            },
            subType: {
                equalTo: ChangeSetType.COSMIC_DOCUMENT_INITIALIZATION
            },
            status: {
                equalTo: ChangeSetStatus.OPEN
            }
        };
    }
    get changeSetsNeedingApproval() {
        const changeSets = this.changeSetsQueryResults?.map((changeSet) => {
            const temp = transformChangeSets([changeSet])[0];
            const document = transformDocuments([changeSet?.document])[0];
            return {
                ...temp,
                _docSubType: document._subType,
                _number: document.number,
                _title: document._title,
                _createdDateTimeTicks: document._createdDateTicks,
                _createdDateTime: document._createdDateTime,
                _createdDate: document._createdDateTime,
                _createdByUser: document._createdByUser,
                document: {
                    id: changeSet?.document?.id,
                    subType: changeSet?.document?.subType
                }
            };
        });
        return this.changeSetsQueryResults ? changeSets : [];
    }
    get homeViewDocuments() {
        const hasActiveDonutLabel = Boolean(this.activeDonutLabel);
        if (!this.homeViewDocumentsQueryResults) {
            return [];
        }
        const items = transformDocuments(this.homeViewDocumentsQueryResults);
        if (hasActiveDonutLabel) {
            return items.filter((item) => item._subType === this.activeDonutLabel);
        }
        else {
            return items;
        }
    }
    buildUrlForDocumentManage(val, tabName = 'information') {
        return `/${DocumentTypeDisplay.get(val.subType)}/manage/${val.id}#${tabName}`;
    }
    buildUrlForDocumentManageFromChangeSet(val) {
        return this.buildUrlForDocumentManage(val.document, 'approvals');
    }
    getDocumentOverviewChartData(item) {
        const steps = item.cosmicDocumentWorkflow?.workOrderSteps.nodes || item.cosmicDocumentRca?.workOrderSteps.nodes || [];
        return {
            labels: [''],
            datasets: [
                {
                    data: [
                        steps.filter((step) => step?.computedStatus === ChangeSetStatus.APPROVED).length
                    ],
                    backgroundColor: '#604496',
                    label: 'Approved'
                },
                {
                    data: [
                        steps.filter((step) => step?.computedStatus === ChangeSetStatus.OPEN).length
                    ],
                    backgroundColor: '#cdaaff',
                    label: 'Open'
                }
            ]
        };
    }
    onClickOfDocument(val) {
        const url = this.buildUrlForDocumentManage(val);
        this.$router.push(url);
    }
    onMetaClickOfDocument(val) {
        const url = this.buildUrlForDocumentManage(val);
        window.open(url);
    }
    created() {
        this.fetchChangeSets();
        this.fetchHomeViewDocuments();
    }
    setActiveDonutLabel(label) {
        this.activeDonutLabel = label;
        if (this.activeDonutLabel) {
            this.$notification.add({
                type: AlertType.INFO,
                text: `Filtering by "${this.activeDonutLabel}".`
            });
        }
    }
    async fetchChangeSets() {
        try {
            const resp = await this.$apollo.query({
                query: gql `
          ${CosmicDocumentSlimFragment}
          ${CosmicUserSlimFragment}

          fragment ChangeSetNeedsApproval on ChangeSet {
            id
            nodeId
            subType

            document {
              ...CosmicDocumentSlim

              createdByUser {
                ...CosmicUserSlim
              }
            }

            status {
              openedDate
              closedDate
              status
            }
          }

          query ChangeSetsNeedingApprovalOnHome($filter: ChangeSetAuthorityFilter) {
            changeSetAuthorities(filter: $filter) {
              totalCount
              nodes {
                id
                nodeId
                changeSet {
                  ...ChangeSetNeedsApproval
                }
              }
            }
          }
        `,
                variables: {
                    filter: this.changeSetApprovalFilters
                }
            });
            const changeSets = resp.data.changeSetAuthorities?.nodes.map((node) => node.changeSet);
            // this.changeSetsQueryResults = uniqBy(changeSets, 'nodeId') || null;
            this.changeSetsQueryResults = changeSets || null;
        }
        catch (err) {
            this.$errorUtility({ err });
        }
    }
    async fetchHomeViewDocuments() {
        try {
            this.isLoading = true;
            const validSubTypesForThisContext = this.currentUserActiveSupportContextValidDocumentTypes;
            const resp = await this.$apollo.query({
                query: gql `
          ${CosmicContactSlimFragment}
          ${CosmicDocumentSlimFragment}
          ${CosmicUserSlimFragment}

          fragment CosmicDocsHomeViewDocument on Document {
            ...CosmicDocumentSlim

            initiatorContact {
              ...CosmicContactSlim
            }

            createdByUser {
              ...CosmicUserSlim
            }

            cosmicDocumentDr {
              computedStatus
              id
              initializationStatus

              createdByUser {
                ...CosmicUserSlim
              }
            }

            cosmicDocumentEzt {
              computedStatus
              id
              initializationStatus

              createdByUser {
                ...CosmicUserSlim
              }
            }

            cosmicDocumentTps {
              computedStatus
              id
              initializationStatus

              createdByUser {
                ...CosmicUserSlim
              }
            }

            cosmicDocumentWorkflow {
              computedStatus
              id

              createdByUser {
                ...CosmicUserSlim
              }

              workOrderSteps {
                nodes {
                  id
                  computedStatus
                }
              }
            }

            cosmicDocumentFracas {
              computedStatus
              id
              initializationStatus

              createdByUser {
                ...CosmicUserSlim
              }
            }

            cosmicDocumentRca {
              computedStatus
              id

              createdByUser {
                ...CosmicUserSlim
              }

              workOrderSteps {
                nodes {
                  id
                  computedStatus
                }
              }
            }
          }

          query CosmicDocsHomeView($subTypes: [String]) {
            searchDocuments(orderBy: [CREATED_DATE_TIME_DESC, NUMBER_DESC], subType: $subTypes) {
              totalCount

              nodes {
                ...CosmicDocsHomeViewDocument
              }
            }
          }
        `,
                variables: {
                    subTypes: validSubTypesForThisContext
                }
            });
            this.isLoading = false;
            this.homeViewDocumentsQueryResults = resp.data.searchDocuments?.nodes || [];
        }
        catch (err) {
            this.isLoading = false;
            this.$errorUtility({ err });
        }
    }
};
HomeView = __decorate([
    Component
], HomeView);
export default HomeView;

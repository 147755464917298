import { __decorate } from "tslib";
import gql from 'graphql-tag';
import { Component, Mixins } from 'vue-property-decorator';
import { ChangeSetStatus, ChangeSetType } from '~/db_types';
import { CosmicDocumentSlimFragment } from '~/nasa_ui/DAL/document/slim';
import { CosmicUserGroupFragment } from '~/nasa_ui/DAL/user';
import BaseCosmic from '~/nasa_ui/base/BaseCosmic';
import { DEFAULT_DASH, cDrBuyoffGroupCode, cTpsBuyoffGroupCode } from '~/nasa_ui/constants';
import { CDOCS_USER_APPROVALS_POLLING_RATE } from '~/nasa_ui/constants/approvals';
import StepMixin from '~/nasa_ui/mixins/StepMixin';
import { ChangeSetTypeDisplay } from '~/nasa_ui/types/enums/change-set';
import { DocumentTypeDisplay, cDocsChangeSetTypes } from '~/nasa_ui/types/enums/document';
import { groupNameDisplay } from '~/nasa_ui/utils/helpers/user';
import { buildIconAsString } from '~/nasa_ui/utils/ui/buildIconAsString';
let MenuMyCdocsApprovals = class MenuMyCdocsApprovals extends Mixins(BaseCosmic, StepMixin) {
    alertLimit = 5;
    isLoading = false;
    fetchFailed = false;
    timerId = null; // interval for polling for updates
    userApprovals = [];
    get approvalMenuItems() {
        return this.filteredUserApprovals
            .map((approval) => {
            const cs = approval?.changeSetAuthority?.changeSet;
            // redundant but shuts up TS
            if (!cs || !approval.changeSetAuthority) {
                return null;
            }
            let title = ChangeSetTypeDisplay.get(cs.subType) || '';
            if (cs.subType === ChangeSetType.WORK_ORDER_STEP) {
                title = `${title} - ${cs.workOrderStep?.stepNumber}`;
            }
            const icon = buildIconAsString({
                iconClass: this.$icons[cs.document?.subType] || 'fad fa-bell-on',
                color: this.EntityType.DOCUMENT
            });
            const description = `${cs.document?.number} · ${cs.document?.title}`;
            const groupName = approval.changeSetAuthority.userGroup
                ? groupNameDisplay(approval.changeSetAuthority.userGroup)
                : DEFAULT_DASH;
            const documentType = cs.document?.subType;
            const documentSlug = DocumentTypeDisplay.get(documentType);
            const slug = this.usesSteps(documentType) ? 'steps' : 'tasks';
            const url = cs.subType === ChangeSetType.WORK_ORDER_STEP
                ? `/${documentSlug}/manage/${cs.document?.id}?step=${cs.workOrderStep?.stepNumber}#${slug}`
                : `/${documentSlug}/manage/${cs.document?.id}#approvals`;
            return {
                description,
                groupName,
                icon,
                id: approval.changeSetAuthority.id,
                title,
                url
            };
        })
            .filter((_) => _);
    }
    get approvalsReportUrl() {
        return '/reporting/user_change_set_approvals';
    }
    get badgeCount() {
        if (this.fetchFailed) {
            return '!';
        }
        const count = this.userChangeSetApprovalCount;
        if (count === 0) {
            return null;
        }
        return count > 9 ? '9+' : `${count}`;
    }
    get filteredUserApprovals() {
        return this.userApprovals.filter((approval) => {
            if (!approval?.changeSetAuthority?.changeSet) {
                return false;
            }
            const changeSet = approval.changeSetAuthority.changeSet;
            // abort if the change set isn't open
            if (changeSet.computedStatus !== ChangeSetStatus.OPEN) {
                return false;
            }
            // abort if it's NOT a cDocs CS type
            if (!cDocsChangeSetTypes.includes(changeSet.subType)) {
                return false;
            }
            // if it's a WorkOrderStep without a WorkOrderStep then this is probably
            // an approval for a deleted step
            if (changeSet.subType === ChangeSetType.WORK_ORDER_STEP && !changeSet.workOrderStep) {
                return false;
            }
            return true;
        });
    }
    get userChangeSetApprovalCount() {
        if (!this.userApprovals) {
            return 0;
        }
        return this.approvalMenuItems.length || 0;
    }
    created() {
        // inital fetch on create
        this.fetchCurrentUserApprovals();
        // listen for approvals menu update requests
        // a bit too generic of an event name now but it's left over from og cosmic
        this.$listen('reload_user_alerts', () => {
            this.fetchCurrentUserApprovals();
        });
    }
    async fetchCurrentUserApprovals() {
        try {
            this.isLoading = true;
            this.fetchFailed = false;
            const resp = await this.$apollo.query({
                query: gql `
          ${CosmicDocumentSlimFragment}
          ${CosmicUserGroupFragment}

          fragment UpcomingCsaOnApprovalsMenu on UpcomingChangeSetAuthority {
            changeSetAuthorityId
            positionInQueue

            changeSetAuthority {
              id
              nodeId

              changeSet {
                computedStatus
                nodeId
                subType

                document {
                  ...CosmicDocumentSlim
                }

                workOrderStep {
                  id
                  nodeId
                  stepNumber
                }
              }

              userGroup {
                ...CosmicUserGroup
              }
            }
          }

          query GetUpcomingChangeSetAuthoritiesForApprovalsMenu($buyoffGroups: [String!]) {
            currentUser {
              nodeId
              upcomingChangeSetAuthorities(
                filter: { groupCode: { notIn: $buyoffGroups }, positionInQueue: { equalTo: 1 } }
              ) {
                totalCount
                nodes {
                  ...UpcomingCsaOnApprovalsMenu
                }
              }
            }
          }
        `,
                variables: {
                    buyoffGroups: [cDrBuyoffGroupCode, cTpsBuyoffGroupCode]
                },
                fetchPolicy: 'network-only'
            });
            this.isLoading = false;
            if (!resp.data.currentUser) {
                console.error('Couldnt get the current user upcoming csas');
                return null;
            }
            this.userApprovals = resp.data.currentUser.upcomingChangeSetAuthorities.nodes;
            // this could be a manual refresh, reset the timer
            if (this.timerId) {
                clearTimeout(this.timerId);
            }
            // schedule next poll for updates
            // `window` needed because otherwise the type system thinks it's a NodeJS call
            this.timerId = window.setTimeout(() => {
                this.fetchCurrentUserApprovals();
            }, CDOCS_USER_APPROVALS_POLLING_RATE);
        }
        catch (err) {
            this.isLoading = false;
            this.fetchFailed = true;
            this.$errorUtility({
                err,
                suppressNotification: true
            });
        }
    }
};
MenuMyCdocsApprovals = __decorate([
    Component
], MenuMyCdocsApprovals);
export default MenuMyCdocsApprovals;

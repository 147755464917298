var _a, _b;
import { __decorate, __metadata } from "tslib";
import gql from 'graphql-tag';
import { Component, Emit, Prop, Watch } from 'vue-property-decorator';
import { InventoryStatus, SearchInventoryQuery, Side } from '~/db_types';
import BaseSearch from '~/nasa_ui/base/BaseSearch';
import { CosmicItemInstanceSlimFragment } from '~/nasa_ui/DAL/itemInstance/slim';
import { CosmicLocationSlimFragment } from '~/nasa_ui/DAL/location/slim';
import { EntityType } from '~/nasa_ui/types';
import { InventoryStatusDisplay, ItemClassesWithoutMultiple, ItemInstanceInventoryTypeDisplay, SideDisplay } from '~/nasa_ui/types/enums/hardware';
import { addFilterOutSelected } from '~/nasa_ui/utils/helpers/displayMappers';
import { InventoryTableHeaders } from '~/nasa_ui/utils/helpers/tableDefinitions';
import { InventoriesDisplay, transformInventories } from '~/nasa_ui/utils/helpers/transformEntityToTableFormat';
let SearchInventory = class SearchInventory extends BaseSearch {
    entityType = EntityType.INVENTORY;
    SideDisplay = SideDisplay;
    hint = 'Drawing number, Description';
    inInventoryLocal = true;
    InventoryStatus = InventoryStatus;
    InventoryStatusDisplay = InventoryStatusDisplay;
    InventoryTableHeaders = InventoryTableHeaders;
    ItemInstanceInventoryTypeDisplay = ItemInstanceInventoryTypeDisplay;
    ItemInstanceInventoryTypeWithoutMultiple = ItemClassesWithoutMultiple;
    placeholder = 'search for inventory';
    Side = Side;
    userSelectedMaxResults = 1000;
    searchFilters = {
        hasQuantity: true,
        includeArchived: false,
        asBuiltNumber: null,
        bin: null,
        building: null,
        crossContext: false,
        drawingNumber: null,
        hasChildren: null,
        isInstalled: null,
        lotNumber: null,
        projectCode: null,
        query: '',
        room: null,
        serialNumber: null,
        side: null,
        status: null,
        stock: null,
        subType: null
    };
    SEARCH_QUERY = gql `
    ${CosmicLocationSlimFragment}
    ${CosmicItemInstanceSlimFragment}

    fragment SearchInventory on Inventory {
      id
      nodeId

      # Item number
      drawingNumber
      lotNumber
      serialNumber
      side
      size

      # AsBuilt
      asBuiltNumber

      # Description
      itemDrawingDescription

      # Qty
      quantity

      # Unit Cost
      unitCost

      # Status
      status

      # Class
      subType

      # Equipment group
      itemMasterSubType

      # Project Code
      projectCode

      isArchived
      isInstalled

      itemMaster {
        nodeId
        inventoryMethod
        inventoryUnitType
        isTool
      }

      itemInstance {
        ...CosmicItemInstanceSlim
      }

      # Building/Stock/Bin
      location {
        ...CosmicLocationSlim
      }
    }

    fragment SearchInventoryCrossContext on InventoryCrossContext {
      asBuiltNumber
      drawingNumber
      inventoryId
      isInstalled
      projectCode
      quantity
      side
      status
      supportOrganizationCode
      unitCost
      itemInstanceCrossContext {
        asBuiltNumber
        description
        drawingNumber
        id
        lotNumber
        serialNumber
        side
        size
      }
      itemInstance {
        ...CosmicItemInstanceSlim
      }
      location {
        ...CosmicLocationSlim
      }
      locationCrossContext {
        bin
        building
        id
        room
        stock
      }
    }

    query searchInventory(
      $asBuiltNumber: [String]
      $bin: [String]
      $building: [String]
      $crossContext: Boolean = false
      $drawingNumber: [String]
      $filter: InventoryFilter
      $filterCrossContext: InventoryCrossContextFilter
      $firstResults: Int
      $hasChildren: Boolean
      $hasQuantity: Boolean
      $includeArchived: Boolean
      $isInstalled: Boolean
      $lotNumber: [String]
      $projectCode: [String]
      $query: String
      $room: [String]
      $serialNumber: [String]
      $side: [Side]
      $status: [InventoryStatus]
      $stock: [String]
      $subType: [ItemInstanceInventoryType]
    ) {
      searchInventory(
        asBuiltNumber: $asBuiltNumber
        bin: $bin
        building: $building
        drawingNumber: $drawingNumber
        filter: $filter
        first: $firstResults
        hasChildren: $hasChildren
        includeArchived: $includeArchived
        isInstalled: $isInstalled
        hasQuantity: $hasQuantity
        lotNumber: $lotNumber
        projectCode: $projectCode
        query: $query
        room: $room
        serialNumber: $serialNumber
        side: $side
        status: $status
        stock: $stock
        subType: $subType
      ) @skip(if: $crossContext) {
        nodes {
          ...SearchInventory
        }
      }
      searchInventoryCrossContext(
        asBuiltNumber: $asBuiltNumber
        bin: $bin
        building: $building
        drawingNumber: $drawingNumber
        filter: $filterCrossContext
        first: $firstResults
        hasChildren: $hasChildren
        hasQuantity: $hasQuantity
        isInstalled: $isInstalled
        lotNumber: $lotNumber
        projectCode: $projectCode
        query: $query
        room: $room
        serialNumber: $serialNumber
        side: $side
        stock: $stock
        subType: $subType
      ) @include(if: $crossContext) {
        nodes {
          ...SearchInventoryCrossContext
        }
      }
    }
  `;
    crossContext;
    hideShowOtherContextFilter;
    get asBuiltFilterDisplay() {
        const asBuiltSearchFilterValue = (this.$get(this, 'pureSearchFilters.asBuiltNumber') || []);
        const hasAsBuiltFilter = asBuiltSearchFilterValue && asBuiltSearchFilterValue.length !== 0;
        if (!hasAsBuiltFilter) {
            return [];
        }
        return asBuiltSearchFilterValue.map((s) => {
            const asBuiltFilterDisplay = {
                close: true,
                color: 'item_instances',
                outline: true,
                text: `-${s}`,
                value: 'asBuiltNumber'
            };
            return asBuiltFilterDisplay;
        });
    }
    get chipsFromFilters() {
        if (!this.pureSearchFilters || Object.keys(this.pureSearchFilters).length === 0) {
            return [];
        }
        let appChips = [];
        appChips = appChips.concat(this.asBuiltFilterDisplay, this.buildAppChipForProperty('asBuiltNumber', 'item_masters'), this.buildAppChipForProperty('bin', 'locations'), this.buildAppChipForProperty('building', 'locations'), this.buildAppChipForProperty('drawingNumber', 'item_drawings'), this.buildAppChipForProperty('lotNumber', 'item_instances'), this.buildAppChipForProperty('projectCode', 'inventories'), this.buildAppChipForProperty('room', 'locations'), this.buildAppChipForProperty('serialNumber', 'item_instances'), this.buildAppChipForProperty('side', 'item_instances', SideDisplay), this.buildAppChipForProperty('status', 'inventories', InventoryStatusDisplay), this.buildAppChipForProperty('stock', 'locations'), this.buildAppChipForProperty('subType', 'item_instances', ItemInstanceInventoryTypeDisplay));
        if (this.searchFilters.isInstalled) {
            appChips.push({
                close: !this.isFilterLocked('isInstalled'),
                color: EntityType.INVENTORY,
                disabled: this.isFilterLocked('isInstalled'),
                filterType: 'isInstalled',
                outline: true,
                text: `${this.searchFilters.isInstalled ? '' : 'Not '}Installed`,
                value: 'isInstalled'
            });
        }
        if (this.searchFilters.hasQuantity) {
            appChips.push({
                close: !this.isFilterLocked('hasQuantity'),
                color: EntityType.INVENTORY,
                disabled: this.isFilterLocked('hasQuantity'),
                filterType: 'hasQuantity',
                outline: true,
                text: `Has Qty`,
                value: 'hasQuantity'
            });
        }
        if (this.searchFilters.hasChildren) {
            appChips.push({
                close: !this.isFilterLocked('hasChildren'),
                color: EntityType.INVENTORY,
                disabled: this.isFilterLocked('hasChildren'),
                filterType: 'hasChildren',
                outline: true,
                text: `Has Children`,
                value: 'hasChildren'
            });
        }
        if (this.searchFilters.crossContext) {
            appChips.push({
                close: true,
                color: EntityType.INVENTORY,
                disabled: false,
                filterType: 'crossContext',
                outline: true,
                text: `Show other Contexts' Inventory`,
                value: 'crossContext'
            });
        }
        if (this.searchFilters.includeArchived) {
            appChips.push({
                close: true,
                color: EntityType.INVENTORY,
                disabled: false,
                filterType: 'includeArchived',
                outline: true,
                text: `Show Archived Inventory`,
                value: 'includeArchived'
            });
        }
        return appChips.filter((key) => key !== undefined);
    }
    get crossContextInventoryTableHeaders() {
        const regInventoryTableHeaderDefs = [...InventoryTableHeaders];
        // Grab the last one 'location'
        let locationHeaderDef = regInventoryTableHeaderDefs.pop();
        if (locationHeaderDef) {
            locationHeaderDef = { ...locationHeaderDef };
            locationHeaderDef.text = `CTX: ${locationHeaderDef.text}`;
            locationHeaderDef.value = '_crossContextLocation';
            regInventoryTableHeaderDefs.push(locationHeaderDef);
        }
        return regInventoryTableHeaderDefs;
    }
    get disableSearch() {
        return !this.anyFiltersCurrentlyActive && this.searchInput?.length < 2;
    }
    get gqlSearchQueryVariables() {
        const base = {
            firstResults: this.userSelectedMaxResults,
            includeArchived: false
        };
        const query = {};
        if (this.searchInput?.trim().length > 0) {
            query.query = this.searchInput;
        }
        const filters = this.pureSearchFilters;
        const variables = Object.assign({}, base, filters, query);
        return variables;
    }
    crossContextSync() {
        this.$set(this.searchFilters, 'crossContext', this.crossContext || false);
    }
    onChangeOfRawResponse(val) {
        if (!val) {
            this.$set(this, 'searchResults', []);
            return;
        }
        // No matter the response type get the obj that contains 'nodes'
        const data = val.searchInventory?.nodes || val.searchInventoryCrossContext?.nodes;
        // add __SELECTED and pull up nodes a level (works from id rather than the standard nodeId)
        const dataWithSelected = this.addSelectedPropIfSelected(data || []);
        // transform the results based on what EntityType this is
        const transformedData = transformInventories(dataWithSelected);
        this.$set(this, 'searchResults', transformedData.filter(addFilterOutSelected));
    }
    statusSync() {
        // clear status when changing cross context
        if (this.searchFilters.crossContext) {
            this.$set(this.searchFilters, 'status', '');
        }
        this.resetSearchResults();
        this.showTable = false;
    }
    onClickOfSearchResult(selectedItem) {
        this.reset();
        return this.isOutputModeString ? selectedItem.id : selectedItem;
    }
    addSelectedPropIfSelected(data) {
        if (!data || !this.existingValues) {
            return data || [];
        }
        return data.map((node) => {
            // Inventory's uniqueness is defined by id here not the usual nodeId
            if (!node.id) {
                return node;
            }
            let ev = this.existingValues || [];
            if (!Array.isArray(ev)) {
                ev = [ev];
            }
            node.__SELECTED = ev.includes(node.id);
            return node;
        });
    }
};
__decorate([
    Prop({
        type: Boolean
    }),
    __metadata("design:type", Boolean)
], SearchInventory.prototype, "crossContext", void 0);
__decorate([
    Prop({
        type: Boolean,
        default: false
    }),
    __metadata("design:type", Boolean)
], SearchInventory.prototype, "hideShowOtherContextFilter", void 0);
__decorate([
    Watch('crossContext', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], SearchInventory.prototype, "crossContextSync", null);
__decorate([
    Watch('rawSearchResponse', { deep: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [typeof (_a = typeof SearchInventoryQuery !== "undefined" && SearchInventoryQuery) === "function" ? _a : Object]),
    __metadata("design:returntype", void 0)
], SearchInventory.prototype, "onChangeOfRawResponse", null);
__decorate([
    Watch('searchFilters.crossContext', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], SearchInventory.prototype, "statusSync", null);
__decorate([
    Emit('input'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [typeof (_b = typeof InventoriesDisplay !== "undefined" && InventoriesDisplay) === "function" ? _b : Object]),
    __metadata("design:returntype", void 0)
], SearchInventory.prototype, "onClickOfSearchResult", null);
SearchInventory = __decorate([
    Component
], SearchInventory);
export default SearchInventory;

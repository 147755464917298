/* unplugin-vue-components disabled */import { render, staticRenderFns } from "./TheSideNav.vue?vue&type=template&id=3d88cca8&scoped=true"
import script from "./TheSideNav.ts?vue&type=script&lang=ts&external"
export * from "./TheSideNav.ts?vue&type=script&lang=ts&external"
import style0 from "./TheSideNav.vue?vue&type=style&index=0&id=3d88cca8&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3d88cca8",
  null
  
)

export default component.exports
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "app full-screen",
    attrs: {
      "data-app": "app"
    }
  }, [_c('TheNavBar', {
    attrs: {
      "outage-alert-state": _vm.outageAlertState
    },
    on: {
      "nav-open-state": _vm.onNavStateChange
    },
    scopedSlots: _vm._u([{
      key: "alerts",
      fn: function () {
        return [_vm.nextUpcomingOutage && _vm.shouldShowOutageBanner ? _c('Alert', {
          staticClass: "header-alert",
          class: {
            nav_open: _vm.navStateOpen
          },
          attrs: {
            "id": "outage-alert",
            "dismissible": !_vm.isNextOutageWithinThreeHours,
            "type": _vm.outageAlertState
          },
          on: {
            "input": _vm.closeAlert
          }
        }, [_c('v-layout', {
          attrs: {
            "row": "",
            "wrap": ""
          }
        }, [_c('v-flex', {
          staticClass: "outage_timeframe",
          class: {
            outage_error: _vm.outageAlertState === _vm.AlertType.ERROR,
            outage_info: _vm.outageAlertState === _vm.AlertType.INFO,
            outage_warning: _vm.outageAlertState === _vm.AlertType.WARNING
          },
          attrs: {
            "xs12": ""
          }
        }, [_vm.shouldShowCountdown ? _c('span', {
          staticClass: "display-1 outage_countdown"
        }, [_vm._v(_vm._s(_vm.timeUntilNextUpcomingOutageStarts))]) : _vm._e(), _vm.isOutageInProgress ? _c('p', {
          staticClass: "mb-0 subheading"
        }, [_c('strong', [_vm._v("Outage in progress")])]) : _c('p', {
          staticClass: "mb-0 subheading"
        }, [_c('strong', [_vm._v("Outage Scheduled")])]), _c('p', {
          staticClass: "mb-0 outage_range"
        }, [_vm._v(" There will be an outage from "), _c('span', {
          staticClass: "text-no-wrap"
        }, [_c('span', {
          staticClass: "outage_range_value"
        }, [_vm._v(_vm._s(_vm.nextUpcomingOutageStart))]), _vm._v(" to "), _c('span', {
          staticClass: "outage_range_value"
        }, [_vm._v(_vm._s(_vm.nextUpcomingOutageEnd))])])])]), _c('v-flex', {
          attrs: {
            "xs12": ""
          }
        }, [_c('span', {
          staticClass: "outage_user_facing_message",
          domProps: {
            "innerHTML": _vm._s(_vm.nextUpcomingOutageWarningText)
          }
        })])], 1)], 1) : _vm._e()];
      },
      proxy: true
    }])
  }), _c('main', {
    staticClass: "content",
    class: {
      'full-screen': !_vm.navStateOpen || _vm.$vuetify.breakpoint.mdAndDown,
      'outage-error': _vm.outageAlertState === _vm.AlertType.ERROR,
      'outage-info': _vm.outageAlertState === _vm.AlertType.INFO,
      'outage-warning': _vm.outageAlertState === _vm.AlertType.WARNING
    }
  }, [_c('router-view')], 1), _c('TheToasterController'), _c('TheFooter', {
    attrs: {
      "nav-state-is-open": _vm.navStateOpen
    },
    on: {
      "logout": _vm.logoutUser
    }
  })], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };
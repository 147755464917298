var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "active_user_support_display"
  }, [!_vm.isReloading ? _c('v-menu', {
    staticClass: "supportMenu",
    attrs: {
      "offset-y": "",
      "left": "",
      "bottom": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function ({
        on: menu
      }) {
        return [_c('button', _vm._g({
          staticClass: "view-user-supports",
          attrs: {
            "disabled": _vm.hasOnlyOneContext,
            "tooltip": "Select COSMIC Context"
          }
        }, {
          ...menu
        }), [_c('i', {
          staticClass: "white--text organization-icon",
          class: _vm.$icons[_vm.EntityType.ORGANIZATION]
        }), _c('span', {
          staticClass: "organization-code-display"
        }, [_vm._v(_vm._s(_vm.currentUserActiveSupportContext))])])];
      }
    }], null, false, 2923900673)
  }, [_vm.showUserSupportContextList ? _c('v-list', _vm._l(_vm.selectOptions, function (support, i) {
    return _c('v-list-tile', {
      key: i,
      on: {
        "click": function ($event) {
          return _vm.handleUserSupportSelection(support.organizationCode);
        }
      }
    }, [_c('v-list-tile-title', [_c('span', {
      staticClass: "pa-3 organizations white--text"
    }, [_vm._v(_vm._s(support.organizationCode))])])], 1);
  }), 1) : _vm._e()], 1) : _vm._e(), _vm.isReloading ? _c('div', {
    staticClass: "loading_container"
  }, [_c('i', {
    staticClass: "fa-spin",
    class: _vm.$icons['reload']
  })]) : _vm._e()], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };
<template><section><h1>Change Log</h1>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/compare/v22.2.1...v22.2.2">22.2.2</a> (2024-11-15)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/1d12ce488a94c943586662af0d94a518387a8130">1d12ce4</a>)</li>
<li><strong>Schema:</strong> update to v45.0.2 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/f6cf1c9775ea0faeac8e9474787bafdaae8bfdb7">f6cf1c9</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/compare/v22.2.0...v22.2.1">22.2.1</a> (2024-11-13)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/ecf38a48157f6b6340171854e55481a68259afcc">ecf38a4</a>)</li>
<li><strong>Schema:</strong> update to v45.0.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/f15ea0f2c1b84417f4f78a04c974947c427ae2de">f15ea0f</a>)</li>
</ul>
<h1><a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/compare/v22.1.5...v22.2.0">22.2.0</a> (2024-11-12)</h1>
<h3>Features</h3>
<ul>
<li><strong>Schema:</strong> update to v44.1.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/e7b886ef4100c62f2c7234d51177d358221d7277">e7b886e</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/compare/v22.1.4...v22.1.5">22.1.5</a> (2024-11-08)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Auth:</strong> is hard. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/5cec0bbdbd21cc6ed8704ca75abe11ed3a08873b">5cec0bb</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/compare/v22.1.3...v22.1.4">22.1.4</a> (2024-11-08)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Auth:</strong> is hard. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/956b4912dec07f9a0f2edede49cd19bbaa9298b4">956b491</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/compare/v22.1.2...v22.1.3">22.1.3</a> (2024-11-06)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/93dc85bb7a4d177d6302324832d6f4c79b08a354">93dc85b</a>)</li>
<li><strong>Schema:</strong> update to v43.3.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/cc9b7d7460e933f421163b32b3db5e1a840beef9">cc9b7d7</a>)</li>
<li><strong>Schema:</strong> update to v43.3.1 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/4a40c6ab0701177e868de2e49a170ff4b8757d68">4a40c6a</a>)</li>
<li><strong>Schema:</strong> update to v43.3.2 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/0f917155b6613c92b73feb2dd3bd382be5b328aa">0f91715</a>)</li>
<li><strong>Schema:</strong> update to v43.3.3 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/70c63a2fa8fa85c6832a1c1ce6a45f80d1758791">70c63a2</a>)</li>
<li><strong>Schema:</strong> update to v43.3.4 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/77bc0fccf0cfe2de3b438294ebe42832c300d5ca">77bc0fc</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/compare/v22.1.1...v22.1.2">22.1.2</a> (2024-11-01)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Schema:</strong> update to v43.2.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/7403cc7df417f49dd8683f4038aebb36fde71421">7403cc7</a>)</li>
<li><strong>Schema:</strong> update to v43.2.1 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/5281487a78c17b1b389cfa6771ec0b24a57daccc">5281487</a>)</li>
<li><strong>Schema:</strong> update to v43.2.2 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/b3ca58b129a0b9663d13370de5af2d02b79a3aa9">b3ca58b</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/compare/v22.1.0...v22.1.1">22.1.1</a> (2024-10-30)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Http dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/7e68b7afe28335c114a0dc5ec0e884452a7405bb">7e68b7a</a>)</li>
<li><strong>JF288:</strong> report export now works correctly (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/e273855621964011194474838f8eec44127f358e">e273855</a>)</li>
<li><strong>Schema:</strong> update to v43.1.1 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/7efd753e9107bd1cbd0e5b46aaa2d04a7c5585c3">7efd753</a>)</li>
<li><strong>Schema:</strong> update to v43.1.2 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/6c056ae9ec5187bdc65cecc0e67d69fd6c6cc8e2">6c056ae</a>)</li>
</ul>
<h1><a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/compare/v22.0.0...v22.1.0">22.1.0</a> (2024-10-23)</h1>
<h3>Features</h3>
<ul>
<li><strong>Schema:</strong> update to v43.1.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/eb07a66ef340f8daf87cdad85c08b2f5eed79338">eb07a66</a>)</li>
</ul>
<h1><a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/compare/v21.0.3...v22.0.0">22.0.0</a> (2024-10-22)</h1>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/c5715c2172681ab21ec02372b520edc733d18c89">c5715c2</a>)</li>
</ul>
<h3>Features</h3>
<ul>
<li><strong>Schema:</strong> update to v43.0.2 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/b458965b6ee1061a6083687488bdca8e1787cb35">b458965</a>)</li>
</ul>
<h3>BREAKING CHANGES</h3>
<ul>
<li><strong>Schema:</strong> Hardware list changes</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/compare/v21.0.2...v21.0.3">21.0.3</a> (2024-10-09)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Comments:</strong> updated to use API v2 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/5ca45d38bfbb32e969b9a7308228ed1812846241">5ca45d3</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/compare/v21.0.1...v21.0.2">21.0.2</a> (2024-10-09)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/2d2a896f4fa951b3764774e8ce43444833f1c193">2d2a896</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/compare/v21.0.0...v21.0.1">21.0.1</a> (2024-10-08)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>RCA export:</strong> updated risk matrix on export (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/25e61a77c6c64eba96d777937cc5c3b8138c02df">25e61a7</a>)</li>
</ul>
<h1><a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/compare/v20.10.3...v21.0.0">21.0.0</a> (2024-10-08)</h1>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/225f5923dd50eef215962aa0a995847309a5a4bb">225f592</a>)</li>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/6fbcb39042b46fd0b76b96710954c30e6d742602">6fbcb39</a>)</li>
<li><strong>Schema:</strong> update to v41.6.1 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/76619e020352650d8f9d90a2aa3d537ae109910c">76619e0</a>)</li>
<li><strong>Schema:</strong> update to v41.6.2 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/f987bf2e46143a01a08c57c18d8d7084b23f4418">f987bf2</a>)</li>
</ul>
<h3>Features</h3>
<ul>
<li><strong>RCA:</strong> new RCA export to PDF (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/22ef77744ae68c5d5d44fa64fcabe87bde2eeae4">22ef777</a>)</li>
</ul>
<h3>BREAKING CHANGES</h3>
<ul>
<li><strong>RCA:</strong> replaces the current RCA export with a new Bernice approved format</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/compare/v20.10.2...v20.10.3">20.10.3</a> (2024-10-03)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>RCA:</strong> rolling back export (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/ea5c77fd37632b7f4df8861c84fcc0e4c0f54156">ea5c77f</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/compare/v20.10.1...v20.10.2">20.10.2</a> (2024-10-03)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/d5e9ab4470ef4c6c0954e201c673baabf34c05ed">d5e9ab4</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/compare/v20.10.0...v20.10.1">20.10.1</a> (2024-10-03)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/749da4778fd82aefdec04594af1210eb9027fb20">749da47</a>)</li>
</ul>
<h1><a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/compare/v20.9.2...v20.10.0">20.10.0</a> (2024-10-02)</h1>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/288a9ae3db67c09f0e348e5bc846612b41541133">288a9ae</a>)</li>
</ul>
<h3>Features</h3>
<ul>
<li><strong>RCA:</strong> export has been revamped to a new format (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/019dbec9a09e108801288d533cccc4932755e120">019dbec</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/compare/v20.9.1...v20.9.2">20.9.2</a> (2024-10-01)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>RCA:</strong> added NASA notification and closure method (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/574fdbecc621bf6d9f271c6bc374496b3950f8d5">574fdbe</a>)</li>
<li><strong>Schema:</strong> update to v41.5.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/7b0700bf958bd9327cce7a2636ab7a4633af9acf">7b0700b</a>)</li>
<li><strong>Schema:</strong> update to v41.6.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/a4c1f05dfe2ee3f03506d2753876f751fafbc6a1">a4c1f05</a>)</li>
<li><strong>SideNav:</strong> fixed issue where active sidebar item wasn’t highlighted (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/0b36bbd4abbe3f34593e015d354b5e9ea731cd49">0b36bbd</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/compare/v20.9.0...v20.9.1">20.9.1</a> (2024-09-25)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/5ad3a7d6ebf14e41fff773e085f136c45ea900f5">5ad3a7d</a>)</li>
<li><strong>Schema:</strong> update to v41.4.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/b5acb4fcaa89a40b1dd12072bef8de051e55479a">b5acb4f</a>)</li>
<li><strong>Schema:</strong> update to v41.4.1 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/879198fc2c695913dff655dc96b11505157c97c2">879198f</a>)</li>
</ul>
<h1><a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/compare/v20.8.1...v20.9.0">20.9.0</a> (2024-09-24)</h1>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/073482e64eca0bc4fcc670deee6aba138908d869">073482e</a>)</li>
<li><strong>Schema:</strong> update to v41.3.1 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/6805f809288437bdc513016a519ea65e247259c1">6805f80</a>)</li>
<li><strong>Schema:</strong> update to v41.3.2 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/787d63dad4a9772c1e3d1cc305a360aa02852577">787d63d</a>)</li>
</ul>
<h3>Features</h3>
<ul>
<li><strong>Docker:</strong> update root node location (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/f39401d6f0f7305e59d46eaad1723fb9575399ad">f39401d</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/compare/v20.8.0...v20.8.1">20.8.1</a> (2024-09-23)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/54f4074e11ee89ffc6266be45c4e618bb4eed1eb">54f4074</a>)</li>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/378b8ace8eeb967e83a107ab9591d2e0b7bb9260">378b8ac</a>)</li>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/2482a92116692f306d3f8378d5bf12ca370408f1">2482a92</a>)</li>
<li><strong>COSMIC:</strong> Mini link updates (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/648988fa13d65ce0956226744376ac9e77801ee5">648988f</a>)</li>
<li><strong>Home:</strong> updated main content background color (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/e9c93d179b4a602c0396764245fc49c33611beaa">e9c93d1</a>)</li>
<li><strong>Schema:</strong> update to v41.1.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/4b9f374e60b216c4c3ba4401a38bd89f50904c2c">4b9f374</a>)</li>
<li><strong>Schema:</strong> update to v41.2.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/1d1076b1653b524d0487486fcb1b1d2ed51b8c4e">1d1076b</a>)</li>
<li><strong>Schema:</strong> update to v41.2.1 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/a8262099e90f151b4daefd49d791411fff8122f4">a826209</a>)</li>
<li><strong>Schema:</strong> update to v41.2.2 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/22a86d9c8c4cf87dcafea81363fcfa9be916684c">22a86d9</a>)</li>
<li><strong>Schema:</strong> update to v41.3.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/13e334d69e6e0faa6984aaf148b4d56ea0e084a3">13e334d</a>)</li>
<li><strong>UI:</strong> improved date picker popover (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/2dce16cad48b9d065cdf96c51debde37d6141eb1">2dce16c</a>)</li>
</ul>
<h1><a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/compare/v20.7.2...v20.8.0">20.8.0</a> (2024-09-18)</h1>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/47a983de9b3562d2701896313ea752ffd5ea8f67">47a983d</a>)</li>
<li><strong>Schema:</strong> update to v41.0.2 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/ae784e7b8297c42b9e0d54d45ca5b27a0530cdf6">ae784e7</a>)</li>
</ul>
<h3>Features</h3>
<ul>
<li><strong>RCA management:</strong> add new RCA fields and move closure info (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/d2f68de9bc3e1901759950b6bfe1c1580572d226">d2f68de</a>)</li>
<li><strong>Schema:</strong> update to v40.0.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/e596c35b4da03a5c7a60bce56ce8057b688122af">e596c35</a>)</li>
<li><strong>Schema:</strong> update to v41.0.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/f6452b9609375b14b9f4011a6689a5323c2cb754">f6452b9</a>)</li>
<li><strong>UI:</strong> redesigned menus (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/12b605f8b5276fc2cd531b85ee4bcf380aee0467">12b605f</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/compare/v20.7.1...v20.7.2">20.7.2</a> (2024-09-12)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/3265992c0e247f63da8e30c96290ba6fadd7f032">3265992</a>)</li>
<li><strong>Schema:</strong> update to v39.0.1 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/d0b7ff1917a03fa14aa2cd5b838fe9eaa41226c0">d0b7ff1</a>)</li>
<li><strong>Schema:</strong> update to v39.0.2 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/cf197934bea3685d74287cfe4a4bac7d2b4f46e8">cf19793</a>)</li>
<li><strong>Schema:</strong> update to v39.1.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/a03dd1b385c10cd7ce9c53af47529719224dfcbe">a03dd1b</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/compare/v20.7.0...v20.7.1">20.7.1</a> (2024-09-05)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/2beb07733d3c467f1a1aa00c71eb59034c5a91e9">2beb077</a>)</li>
<li><strong>UI:</strong> streamlined checkbox controls (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/7b0758eef03dea4f93aa6f43a9019c4c4a87a901">7b0758e</a>)</li>
</ul>
<h1><a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/compare/v20.6.3...v20.7.0">20.7.0</a> (2024-08-30)</h1>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/95e6b3d2ac9564a033b25f597e2bc4ee16f4d224">95e6b3d</a>)</li>
<li><strong>Schema:</strong> update fixes breaks from v39.0.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/0a663775700ea30eb21c96beafd46f65f4d8cddc">0a66377</a>)</li>
<li><strong>UI:</strong> streamlined radio button controls (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/384ac7b2432d0f22640779e097f4a76adcac5c00">384ac7b</a>)</li>
</ul>
<h3>Features</h3>
<ul>
<li><strong>Schema:</strong> update to v39.0.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/8ec5d32af1fc3a665ef58f6f4ed2fd176452147c">8ec5d32</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/compare/v20.6.2...v20.6.3">20.6.3</a> (2024-08-27)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/30fb9b88852185196b7f085ee2c0b91d4aa33aaf">30fb9b8</a>)</li>
<li><strong>RCA:</strong> create updated to more closely match the manage page (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/8dc9ed1f90c232c92b4bf34b8471413df4b7cd09">8dc9ed1</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/compare/v20.6.1...v20.6.2">20.6.2</a> (2024-08-27)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>GCAR:</strong> update create with spacing (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/516c41b6c6c56b2889a5f755606831a16e98c5f5">516c41b</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/compare/v20.6.0...v20.6.1">20.6.1</a> (2024-08-27)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Card:</strong> implementation of new cards with min/max options (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/a8df52061420ef0d99f4232044dbe5003840125f">a8df520</a>)</li>
</ul>
<h1><a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/compare/v20.5.1...v20.6.0">20.6.0</a> (2024-08-27)</h1>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/f09e892c8020ee9076cc2107ab88f69a57d159d8">f09e892</a>)</li>
</ul>
<h3>Features</h3>
<ul>
<li><strong>GCAR:</strong> update provides limited features for GCAR (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/7402a7da30d30ff3b1838fc445601c627d399326">7402a7d</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/compare/v20.5.0...v20.5.1">20.5.1</a> (2024-08-25)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/e8e26f6d865817dd171ced4693de727175d8b570">e8e26f6</a>)</li>
</ul>
<h1><a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/compare/v20.4.2...v20.5.0">20.5.0</a> (2024-08-21)</h1>
<h3>Features</h3>
<ul>
<li><strong>cRCA:</strong> update UX (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/08e7845162eed5daa042b530e5cb323780e33150">08e7845</a>)</li>
<li><strong>Modal:</strong> implementing new webcomponent backed modal (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/5180f4101c08acc0d24101c10af489a7a0bf1798">5180f41</a>)</li>
<li><strong>Workflows:</strong> clean up brings new ui and improved functionality to api2 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/8dcc05b7b914bb4b08d39b9e997ae2e0db9d20b9">8dcc05b</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/compare/v20.4.1...v20.4.2">20.4.2</a> (2024-08-18)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/cfe6da0623829e3a8135daf7fbdfa08eb1329187">cfe6da0</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/compare/v20.4.0...v20.4.1">20.4.1</a> (2024-08-17)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/e74c71237b1be08b01b553a799f4eded30bbb444">e74c712</a>)</li>
<li><strong>Schema:</strong> update to v38.4.3 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/70f495f7cd3ae422d434251ca2857316ea43290e">70f495f</a>)</li>
<li><strong>Schema:</strong> update to v38.5.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/a6ea20439a6f223bb6164d8ba2afc8ad2f37325d">a6ea204</a>)</li>
</ul>
<h1><a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/compare/v20.3.2...v20.4.0">20.4.0</a> (2024-08-13)</h1>
<h3>Features</h3>
<ul>
<li><strong>Admins view:</strong> update adds more application level details to a new admin tab (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/cf27a90c4c0efa41b9b759e176258dfb34e1a46d">cf27a90</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/compare/v20.3.1...v20.3.2">20.3.2</a> (2024-08-13)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Schema:</strong> update to v38.4.1 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/e0570900455762570589aba80abb81a936bebce5">e057090</a>)</li>
<li><strong>Schema:</strong> update to v38.4.2 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/ee9e6fae11148ceafac5ce2b6c13e09e0e745d9c">ee9e6fa</a>)</li>
<li><strong>Workflows:</strong> updated buttons on confirmation modal (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/e3ffc63aa8d32ef8f8c3b97d391b304167b315b5">e3ffc63</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/compare/v20.3.0...v20.3.1">20.3.1</a> (2024-08-07)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Color:</strong> corrects most color issues (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/3facc84ebd0591d6bb58a4602ede87930bfc9acd">3facc84</a>)</li>
<li><strong>Icons:</strong> updates colors of icons (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/29a4ee7c54567b09227649cf0e8e6b6520cce56f">29a4ee7</a>)</li>
</ul>
<h1><a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/compare/v20.2.0...v20.3.0">20.3.0</a> (2024-08-07)</h1>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Navbar:</strong> correct Favorite btn alignment (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/e944cb107da909b78acd57aaba1bc630f9816a67">e944cb1</a>)</li>
<li><strong>Schema:</strong> update to v38.2.2 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/ae86ad081e481e767a51323e342eee00696abf38">ae86ad0</a>)</li>
<li><strong>Schema:</strong> update to v38.3.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/cb23fa5c015c08f2d981dd9b79ac37027852d1db">cb23fa5</a>)</li>
<li><strong>Schema:</strong> update to v38.4.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/58e41c26f7a672deb773be36f6a09a248c38eb06">58e41c2</a>)</li>
<li><strong>Tables:</strong> update width column for maximum niceness (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/3c8d99770f9e756fce48914d541b1582d2c59f88">3c8d997</a>)</li>
</ul>
<h3>Features</h3>
<ul>
<li><strong>RCA Closure:</strong> adding NASA closure date and lessons learned (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/00c52826b8ede7b0913bab05cd023e937fafffcb">00c5282</a>)</li>
</ul>
<h1><a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/compare/v20.1.1...v20.2.0">20.2.0</a> (2024-08-01)</h1>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Side nav:</strong> now uses web components; functions the same (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/1a6f6b059705b9d411b1758101b8693a11eacdfe">1a6f6b0</a>)</li>
</ul>
<h3>Features</h3>
<ul>
<li><strong>Home:</strong> updates home page to be more useful (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/c6576871e3839183a474097d000f2d47a02e9461">c657687</a>)</li>
<li><strong>UI:</strong> implemented new button web component (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/b7b192507d08887e80b71a7a081480224db8284a">b7b1925</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/compare/v20.1.0...v20.1.1">20.1.1</a> (2024-07-31)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/90778c671e7bc69fbf06a9372268f051e7adcb2a">90778c6</a>)</li>
<li><strong>Schema:</strong> update to v38.0.1 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/d3fb13fd214fadc4bdfa5a6157819cbb89f83bf0">d3fb13f</a>)</li>
<li><strong>Schema:</strong> update to v38.1.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/b1c85968bb7505ed1caf5a367ef9ac8193982a3b">b1c8596</a>)</li>
<li><strong>Schema:</strong> update to v38.2.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/dd522c81853970b398e4dd05706c753a6564c7b5">dd522c8</a>)</li>
<li><strong>Schema:</strong> update to v38.2.1 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/72615ed68e708ec4049c746875fab19ac1b0c628">72615ed</a>)</li>
<li><strong>Standardization:</strong> implement icons in top nav as well as other wonderful augmentations. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/09e295a640da0377e0286ddfe18fe863443e2feb">09e295a</a>)</li>
</ul>
<h1><a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/compare/v20.0.0...v20.1.0">20.1.0</a> (2024-07-25)</h1>
<h3>Bug Fixes</h3>
<ul>
<li><strong>UI:</strong> restored more familiar hardware icons and updated the edit icon (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/d44ab9b70850be9431780b4691afc918b1451453">d44ab9b</a>)</li>
</ul>
<h3>Features</h3>
<ul>
<li><strong>Authorization:</strong> overhauled the order of operations for authorization and authentication (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/a189f5a6d7e95a89aee4d3873abf7f08a6314a86">a189f5a</a>)</li>
</ul>
<h1><a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/compare/v19.0.1...v20.0.0">20.0.0</a> (2024-07-22)</h1>
<h3>Features</h3>
<ul>
<li><strong>Schema:</strong> update to v38.0.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/1ba04553ea70ed177ff1c5516e18086002fb1b97">1ba0455</a>)</li>
</ul>
<h3>BREAKING CHANGES</h3>
<ul>
<li><strong>Schema:</strong> date/time updates system wide</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/compare/v19.0.0...v19.0.1">19.0.1</a> (2024-07-18)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Build:</strong> correct build error preventing deployment (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/04f5c284fc3110947d2afc341c58838e9aabd06e">04f5c28</a>)</li>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/199dc45fa9ad8ba392d5f6a92ec434469aef3f2e">199dc45</a>)</li>
</ul>
<h1><a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/compare/v18.0.3...v19.0.0">19.0.0</a> (2024-07-11)</h1>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Asset pathing:</strong> update to point back to the proxy (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/32545bdf9ceafc6df8dcfd89cd0cc6251d9ba2e1">32545bd</a>)</li>
<li><strong>COSMIC:</strong> Dependency update for deployment. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/022759135870b2491ee8bfe1dd7e3a3b212ae833">0227591</a>)</li>
<li><strong>Schema:</strong> update to v36.0.2 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/975c27af7bf519572c207e0dc119b3458a42dd6e">975c27a</a>)</li>
<li><strong>Schema:</strong> update to v37.0.1 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/3d7689d523a874bfd5df89b2475864e321d5bb56">3d7689d</a>)</li>
</ul>
<h3>Features</h3>
<ul>
<li><strong>Schema:</strong> update to v36.0.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/dce09c0b953336a5090fb9c7f200aadc74915fe2">dce09c0</a>)</li>
<li><strong>Tables:</strong> change to table styles (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/ae07164322f6ae4db70fcba161d8870054e47191">ae07164</a>)</li>
</ul>
<h3>BREAKING CHANGES</h3>
<ul>
<li><strong>Schema:</strong> column renames and date/time related updates</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/compare/v18.0.2...v18.0.3">18.0.3</a> (2024-06-25)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Asset path:</strong> Update GCP bucket url. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/4aa55eb7129238bb1793cf7e55ae20fe31d2d948">4aa55eb</a>)</li>
<li><strong>Footer:</strong> updated knowledgebase link (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/cdcf0ccd36ce9beb7e93c6c2316e5d8b6ebca40d">cdcf0cc</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/compare/v18.0.1...v18.0.2">18.0.2</a> (2024-06-20)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Pathing:</strong> update cosmic paths (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/a08558d167c541f78ea33b8a560bcfd830c9633f">a08558d</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/compare/v18.0.0...v18.0.1">18.0.1</a> (2024-06-20)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Outages:</strong> updated outages banner (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/7f503f9ef92e0153875b5fb3a2b993753a1b906a">7f503f9</a>)</li>
<li><strong>Schema:</strong> update to v35.1.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/34c85f801a8b80b156c012dba171a0e4bda30e6e">34c85f8</a>)</li>
<li><strong>Schema:</strong> update to v35.1.1 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/5da0a1b090a6d002b4fefe628732b8f566feb772">5da0a1b</a>)</li>
</ul>
<h1><a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/compare/v17.1.4...v18.0.0">18.0.0</a> (2024-06-18)</h1>
<h3>Features</h3>
<ul>
<li><strong>Schema:</strong> update to v35.0.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/920601a39afeb6d3a3b3dff4d40fd06a6f7af64a">920601a</a>)</li>
</ul>
<h3>BREAKING CHANGES</h3>
<ul>
<li><strong>Schema:</strong> createdDate/updatedDate rename</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/compare/v17.1.3...v17.1.4">17.1.4</a> (2024-06-18)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Schema:</strong> update to v34.1.1 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/d85bbcc5a3335a5a0a5b06fdaadea2c3000a0f36">d85bbcc</a>)</li>
<li><strong>Schema:</strong> update to v34.1.2 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/ed12e8b3f156712217e884ff06d73d6006669a6a">ed12e8b</a>)</li>
<li><strong>Schema:</strong> update to v34.1.3 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/7696a4aa0875393d926c11d61ca05b64c47f2cef">7696a4a</a>)</li>
<li><strong>Static assets:</strong> update pathing per environment (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/f6673488370cb556ce1ce9b00870c5afaafcb991">f667348</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/compare/v17.1.2...v17.1.3">17.1.3</a> (2024-06-17)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/90641b136aab0d84a62c9c883743822df345f433">90641b1</a>)</li>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/81ae90eb61d943b37bffff669ab831c53685d5b3">81ae90e</a>)</li>
<li><strong>Schema:</strong> update to v34.1.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/a306f60ac6a46aea5503fbb8ad8d4ce17bcb00f4">a306f60</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/compare/v17.1.1...v17.1.2">17.1.2</a> (2024-06-14)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Schema:</strong> update to v34.0.2 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/d117ee81c61df77c6dd7a656b721aea965259aa7">d117ee8</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/compare/v17.1.0...v17.1.1">17.1.1</a> (2024-06-12)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Schema:</strong> update to v34.0.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/7f49a5eee69c1eb7a20604a6413855b50855ddbb">7f49a5e</a>)</li>
<li><strong>Schema:</strong> update to v34.0.1 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/0f023a3d926a2412d94567a0630975c722165b49">0f023a3</a>)</li>
</ul>
<h1><a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/compare/v17.0.1...v17.1.0">17.1.0</a> (2024-06-11)</h1>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Schema:</strong> update to v32.1.1 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/231ccd3e1caa47724ad80c8cff2194c6ba80edb8">231ccd3</a>)</li>
<li><strong>Schema:</strong> update to v33.0.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/c90cff05b84ea623592616b30da3808383b995b4">c90cff0</a>)</li>
</ul>
<h3>Features</h3>
<ul>
<li><strong>Dates:</strong> attempt at fixing datetimes (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/f4419074ad4a38aa13052dce2d78c50ae6ffddc1">f441907</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/compare/v17.0.0...v17.0.1">17.0.1</a> (2024-06-10)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Misc:</strong> update static image locations (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/9f6fee71e9d7d03eef7dc36001ad33bcd63837a3">9f6fee7</a>)</li>
</ul>
<h1><a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/compare/v16.1.0...v17.0.0">17.0.0</a> (2024-06-10)</h1>
<h3>Features</h3>
<ul>
<li><strong>Static resource mgmt:</strong> change implements current best practices for serving static content (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/ee66e2aead5dfdaf28d28196daea5c9287c42b08">ee66e2a</a>)</li>
</ul>
<h3>BREAKING CHANGES</h3>
<ul>
<li><strong>Static resource mgmt:</strong> images could need to be remapped</li>
</ul>
<h1><a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/compare/v16.0.6...v16.1.0">16.1.0</a> (2024-06-07)</h1>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/bb4ad71e6d00c8f5c7415e5da1136177f968d95f">bb4ad71</a>)</li>
<li><strong>Schema:</strong> update to v31.2.1 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/135b88a857de7479a199375663941ac4f5ace586">135b88a</a>)</li>
<li><strong>Schema:</strong> update to v32.0.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/653f0f460807e81497d0752b79b83dfaf5737f21">653f0f4</a>)</li>
</ul>
<h3>Features</h3>
<ul>
<li><strong>Date:</strong> updates old date control with a date picker (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/fd39f2f6c77a752c01a7c28b879f406d0c963387">fd39f2f</a>)</li>
<li><strong>Schema:</strong> update to v32.1.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/e6b94c1da07250cf038e4ff6aea8360e80baf5d5">e6b94c1</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/compare/v16.0.5...v16.0.6">16.0.6</a> (2024-06-04)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Schema:</strong> update to v31.2.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/6fc5d6ffb8b7f83d65091c71b207686d6e97376f">6fc5d6f</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/compare/v16.0.4...v16.0.5">16.0.5</a> (2024-06-03)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Schema:</strong> update to v31.1.1 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/d4d8461dd376fe05b4a434715f783d092b38c73f">d4d8461</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/compare/v16.0.3...v16.0.4">16.0.4</a> (2024-05-31)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Schema:</strong> update to v31.0.3 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/427d3a0e6deb60597ce4fac87660f44f5f77cd28">427d3a0</a>)</li>
<li><strong>Schema:</strong> update to v31.1.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/1fd1179096e46c0cccc0c1b320f444e1860e0447">1fd1179</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/compare/v16.0.2...v16.0.3">16.0.3</a> (2024-05-28)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Schema:</strong> update to v31.0.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/72619df6d4f5aac774877bd5443d54df08eb91af">72619df</a>)</li>
<li><strong>Schema:</strong> update to v31.0.1 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/ea52279b8166e504994b2919d348efa6d45b7796">ea52279</a>)</li>
<li><strong>Schema:</strong> update to v31.0.2 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/44af3f4b1060a5eeb2c232950eb88d59ea38f22b">44af3f4</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/compare/v16.0.1...v16.0.2">16.0.2</a> (2024-05-28)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>JF288:</strong> section 3 mappings, section 5 validation (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/0b32b32cd3bef50647925e4018fbeb00ccd4a2a2">0b32b32</a>)</li>
<li><strong>Schema:</strong> update to v30.2.1 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/72eec70ea54facaf5a5dda7b401c7e24fc1a9c5f">72eec70</a>)</li>
<li><strong>Schema:</strong> update to v30.3.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/9236a9e337d54897e481d3cc17ea43b3cf644832">9236a9e</a>)</li>
<li><strong>Schema:</strong> update to v30.3.1 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/2fafb9e506a2c3912900d0908fc10a840d0eb39f">2fafb9e</a>)</li>
<li><strong>Schema:</strong> update to v30.3.2 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/8d21e8967ac6cb3ef4e0d8da5b8cc15ab374e8d1">8d21e89</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/compare/v16.0.0...v16.0.1">16.0.1</a> (2024-05-20)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Schema:</strong> update to v30.1.2 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/30821d8e5919d77bc2847d9473aa70d73e14cf6a">30821d8</a>)</li>
<li><strong>Schema:</strong> update to v30.1.3 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/2ab1727b764123b1b99ab50dd8f905b0b35e7d9e">2ab1727</a>)</li>
<li><strong>Schema:</strong> update to v30.1.4 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/c263722fec6f60243e1bfb2736603eb87ff075e8">c263722</a>)</li>
<li><strong>Schema:</strong> update to v30.2.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/e1055a7549e0d1b4da1a132075cd671c23623acd">e1055a7</a>)</li>
</ul>
<h1><a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/compare/v15.0.0...v16.0.0">16.0.0</a> (2024-05-09)</h1>
<h3>Bug Fixes</h3>
<ul>
<li><strong>JF288:</strong> updated section 5 validation, dropdowns (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/03beab12824975da834834589e311eabc1ffe4c5">03beab1</a>)</li>
<li><strong>Schema:</strong> update to v30.1.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/a63769fb3e220f79f78c6fb636dab8da0d89eaef">a63769f</a>)</li>
<li><strong>Schema:</strong> update to v30.1.1 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/34f68f4515475d99a3f6512d2ed5421e8c94d2f0">34f68f4</a>)</li>
</ul>
<h3>Features</h3>
<ul>
<li><strong>Schema:</strong> update to v29.0.1 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/6f5c6f370b17fc999bd2d169f5f5f04a91b3bebd">6f5c6f3</a>)</li>
<li><strong>Schema:</strong> update to v30.0.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/6ce9e3c1491f229d33e4cf5c03c950ca1be5723e">6ce9e3c</a>)</li>
</ul>
<h3>BREAKING CHANGES</h3>
<ul>
<li><strong>Schema:</strong> no user facing updates massive typings changes</li>
</ul>
<h1><a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/compare/v14.0.4...v15.0.0">15.0.0</a> (2024-04-29)</h1>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/e60f44f1be018880856999e61018403f90274481">e60f44f</a>)</li>
<li><strong>Workflow Cloning:</strong> Tasks will retain their task number when being cloned; Removed Promise.alls. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/94483b08a7187981b4364ff432f1969fb5d3b667">94483b0</a>)</li>
</ul>
<h3>Features</h3>
<ul>
<li><strong>Types:</strong> update includes adding submodule access for api types (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/5d7d9746199b9cb2bcd48e1b508054b1cf6cfddd">5d7d974</a>)</li>
</ul>
<h3>BREAKING CHANGES</h3>
<ul>
<li><strong>Types:</strong> schema updates</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/compare/v14.0.3...v14.0.4">14.0.4</a> (2024-04-24)</h2>
<h3>Bug Fixes</h3>
<ul>
<li>Dockerfile artifact registry project (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/f1f77cd0a4dc2301a27b309f19bfbf2482309135">f1f77cd</a>)</li>
<li><strong>Schema:</strong> Update to 27.1.1 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/8260534bb2206c18e85721a2ee3ad9264fff0832">8260534</a>)</li>
<li><strong>Schema:</strong> Update to 28.0.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/8f71118b76a56b670b95ae1d8621e6c211d926c8">8f71118</a>)</li>
<li><strong>Schema:</strong> update to v27.0.2 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/447d4c69b5078f9da43df2f717befe4a51367cca">447d4c6</a>)</li>
<li><strong>Schema:</strong> update to v27.1.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/fd8431791a8fc1a4140c66e5ed0ea11dd86ad25a">fd84317</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/compare/v14.0.2...v14.0.3">14.0.3</a> (2024-04-18)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/19771012728c99e344d785dd2010fa0b0bfd550a">1977101</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/compare/v14.0.1...v14.0.2">14.0.2</a> (2024-04-18)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>JF288:</strong> updated template spreadsheet (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/110a4c25c5657c4a8362f642cf5148f780d15d98">110a4c2</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/compare/v14.0.0...v14.0.1">14.0.1</a> (2024-04-17)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/7d2ed441fd48fbca4a502e90bc4d1c2f6fc7976f">7d2ed44</a>)</li>
<li><strong>Schema:</strong> update to v27.0.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/f4184548f65bd83e93ebaef7803fbf4bcc9e0fd0">f418454</a>)</li>
</ul>
<h1><a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/compare/v13.1.1...v14.0.0">14.0.0</a> (2024-04-16)</h1>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Schema:</strong> update to v25.0.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/29fcc080792b3de6a29e3110ebb11416957a1418">29fcc08</a>)</li>
</ul>
<h3>Features</h3>
<ul>
<li><strong>Schema:</strong> update to v26.0.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/bfd818d3eef5970b3e07c7ccbebf45b93348ea88">bfd818d</a>)</li>
</ul>
<h3>BREAKING CHANGES</h3>
<ul>
<li><strong>Schema:</strong> No visual changes.</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/compare/v13.1.0...v13.1.1">13.1.1</a> (2024-04-15)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/8a864d7a4cbbb492b0c44fa23d380da19f391c51">8a864d7</a>)</li>
<li><strong>JF288:</strong> updated parsing of spreadsheet (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/03918085472b6717da131765ce5f95afbc3bad2d">0391808</a>)</li>
</ul>
<h1><a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/compare/v13.0.2...v13.1.0">13.1.0</a> (2024-04-12)</h1>
<h3>Bug Fixes</h3>
<ul>
<li><strong>EZT:</strong> updated project code and item class dropdowns (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/6ade29fda22a104f94381b84f4d74018c29161b5">6ade29f</a>)</li>
<li><strong>Outages:</strong> updated banner, fixed side nav height (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/ea256dde4f65d37dcf6f8f91cf6d147123fdb938">ea256dd</a>)</li>
<li><strong>Schema:</strong> update to v20.0.1 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/a198fc65be087af5995de41714edaf635d653a96">a198fc6</a>)</li>
<li><strong>Schema:</strong> update to v20.1.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/3c8f7d456e414df52630b27e73c8cad2a7433721">3c8f7d4</a>)</li>
<li><strong>Schema:</strong> update to v20.1.1 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/120cc1965a494ed435d8b2d1ba4519b6b10a9e1e">120cc19</a>)</li>
<li><strong>Schema:</strong> update to v20.2.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/70a81e702997a94a556cceec1c0f5e193637d257">70a81e7</a>)</li>
<li><strong>Schema:</strong> update to v20.2.2 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/c1e00e31e7c036289e46585b3495ecaa1826d46f">c1e00e3</a>)</li>
<li><strong>Schema:</strong> update to v23.0.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/d05429b8d668a17aa18e670f3decd5953f731177">d05429b</a>)</li>
<li><strong>Schema:</strong> update to v23.0.1 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/ed72936bcd02da0e35de5fb909c133cc5a658bf8">ed72936</a>)</li>
</ul>
<h3>Features</h3>
<ul>
<li><strong>Schema:</strong> update to v21.0.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/cf0b6b2f7a2d2496ae55cd1fa5b1895eacf1bd0d">cf0b6b2</a>)</li>
<li><strong>Schema:</strong> update to v22.0.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/362b132ec32c277dfcb193fbdd9474819de60ec6">362b132</a>)</li>
<li><strong>Schema:</strong> update to v24.0.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/2cc17c898edfab6096b31e7b773c6022e7208cc0">2cc17c8</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/compare/v13.0.1...v13.0.2">13.0.2</a> (2024-03-25)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/3b923afa06925b05b5efb15f65c0fddc7a73904e">3b923af</a>)</li>
<li><strong>Schema:</strong> update to v20.0.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/67b004793609e23f45a2213093ea7d9643c319d9">67b0047</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/compare/v13.0.0...v13.0.1">13.0.1</a> (2024-03-19)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Delivery Matrix item completion:</strong> attachment description requirement works as intended (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/97036c1c074c7613ece86eb5fd73c183a6c2e275">97036c1</a>)</li>
<li><strong>Schema:</strong> update to v19.0.1 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/30c5ee958e7ffe176f3cd940b3b3e9c198332d4f">30c5ee9</a>)</li>
<li><strong>Schema:</strong> update to v19.0.2 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/bdc140151fd09a5099075ef7035c17a7a933737f">bdc1401</a>)</li>
</ul>
<h1><a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/compare/v12.3.0...v13.0.0">13.0.0</a> (2024-03-18)</h1>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Footer:</strong> updated accessibility link (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/196dc98e662f34731240529ca03ff7ff813c4629">196dc98</a>)</li>
<li><strong>Schema:</strong> update to v18.0.1 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/d30b88f4f0f1475c306432116e55d61cd73608c9">d30b88f</a>)</li>
</ul>
<h3>Features</h3>
<ul>
<li><strong>Schema:</strong> update to v18.1.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/9b6c07ffcacdd739d4946d3e1d97a21959f35709">9b6c07f</a>)</li>
<li><strong>Schema:</strong> update to v19.0.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/e582095abee6541bc5ef29f732c48601f2520d14">e582095</a>)</li>
</ul>
<h3>BREAKING CHANGES</h3>
<ul>
<li><strong>Schema:</strong> Comments/Link api change</li>
</ul>
<h1><a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/compare/v12.2.3...v12.3.0">12.3.0</a> (2024-03-14)</h1>
<h3>Features</h3>
<ul>
<li><strong>Schema:</strong> update to v18.0.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/f70df1f0f5a77a50c5122a81e11d6ae0e317b8ec">f70df1f</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/compare/v12.2.2...v12.2.3">12.2.3</a> (2024-03-13)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>DOCS:</strong> Update dependencies. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/1bf8628135b2148a581302ae8341a04b96d41a88">1bf8628</a>)</li>
<li><strong>Schema:</strong> update to v17.0.4 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/77859e05307597d648be99312b62b4c64cdf1f16">77859e0</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/compare/v12.2.1...v12.2.2">12.2.2</a> (2024-03-08)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>DOCS:</strong> Update dependencies. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/b37aa1669897f29b0c0f905d49edbde28372cbd0">b37aa16</a>)</li>
<li><strong>DOCS:</strong> Update dependencies. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/c49f537a1c40a76c239e2892960ee3579f13d70b">c49f537</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/compare/v12.2.0...v12.2.1">12.2.1</a> (2024-03-08)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/09717d734b6c0c77756b0218ffcb6e3b4c9fa4a6">09717d7</a>)</li>
<li><strong>DOCS:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/ecac31e657d5bf65126a5d0b6c66ea19a03ee8f7">ecac31e</a>)</li>
<li><strong>Schema:</strong> update to v17.0.2 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/a04785cb45c0a2b56708d550791b8f1b71cac298">a04785c</a>)</li>
</ul>
<h1><a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/compare/v12.1.3...v12.2.0">12.2.0</a> (2024-03-07)</h1>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/9bfaada33cf506ac754788012a1f92995b3b2afb">9bfaada</a>)</li>
<li><strong>Delivery Matrix items search:</strong> remove pagination from table and make it able to show all available table items (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/6bb5dd94b4b2eb78a7baa7ccb9e96604965cdd10">6bb5dd9</a>)</li>
</ul>
<h3>Features</h3>
<ul>
<li><strong>Schema:</strong> update to v16.1.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/3063f16598f6f8f73a351901725b5d2e4b23ae84">3063f16</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/compare/v12.1.2...v12.1.3">12.1.3</a> (2024-02-28)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Schema:</strong> update to v15.0.7 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/7b25bedba5650f932aec27d39cc6e7000aacd7e0">7b25bed</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/compare/v12.1.1...v12.1.2">12.1.2</a> (2024-02-28)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/976b3d42cdb2b49cac1210536abc4472f2694c71">976b3d4</a>)</li>
<li><strong>Delivery Matrix:</strong> fixed search table filtering and fixed an issue where Hardware Lists would be disassociated from a Delivery Matrix item (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/f5b339a8d842646b2e4f756c38410ce834057e2e">f5b339a</a>)</li>
<li><strong>Schema:</strong> update to v15.0.6 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/02891e6b98e2e32c9ada51d7b2969b8a43964481">02891e6</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/compare/v12.1.0...v12.1.1">12.1.1</a> (2024-02-22)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Schema:</strong> update to v15.0.5 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/54c2be13a5ba1ab08db59655a871874183a2996e">54c2be1</a>)</li>
</ul>
<h1><a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/compare/v12.0.4...v12.1.0">12.1.0</a> (2024-02-21)</h1>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Schema:</strong> update to v15.0.2 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/a9f55dfe91dd4977e5fb15a826897efb939faac4">a9f55df</a>)</li>
<li><strong>Schema:</strong> update to v15.0.3 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/8975e4f0af23e061de6bcd08cdd935d51b093640">8975e4f</a>)</li>
</ul>
<h3>Features</h3>
<ul>
<li><strong>Schema:</strong> update to v15.0.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/523d43295b9b8b02847ee1b588c7d4f9ba308beb">523d432</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/compare/v12.0.3...v12.0.4">12.0.4</a> (2024-02-16)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/5341665db7c1317d7245cd9ef2fdcedc63b0d787">5341665</a>)</li>
<li><strong>Schema:</strong> update to v14.1.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/d5061e1e7f3578edd44ad16243ad9cc717410fbd">d5061e1</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/compare/v12.0.2...v12.0.3">12.0.3</a> (2024-02-15)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/a968a343a45badde3f19efbf2679a304496620d3">a968a34</a>)</li>
<li><strong>DOCS:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/1ad35d4f05f471a063d639c30950415d2900d118">1ad35d4</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/compare/v12.0.1...v12.0.2">12.0.2</a> (2024-02-15)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>User:</strong> update how we find currentUserDelegateGroup (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/11afca0d2c18af8e81c0c322e81c6e03e8301d4b">11afca0</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/compare/v12.0.0...v12.0.1">12.0.1</a> (2024-02-14)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/afb1c0d88b358ee7d586cfc219cf5af07d5fa35a">afb1c0d</a>)</li>
<li><strong>WorkflowHistory:</strong> patch for displaying names of groups (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/a623f0df31b8e790e3c0603f71045ac1557bed80">a623f0d</a>)</li>
</ul>
<h1><a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/compare/v11.0.8...v12.0.0">12.0.0</a> (2024-02-14)</h1>
<h3>Bug Fixes</h3>
<ul>
<li>CI template v4 update (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/81fc1e448af3114af60e1e1c8b9c08932b892055">81fc1e4</a>)</li>
</ul>
<h3>Features</h3>
<ul>
<li><strong>Schema:</strong> update to v14.0.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/f0b3d89f4906fbaf4a5994bbc11f857dd3d88d30">f0b3d89</a>)</li>
<li><strong>User:</strong> update to new api (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/e67414be1c091fe52fffbab87e3804f575262574">e67414b</a>)</li>
</ul>
<h3>BREAKING CHANGES</h3>
<ul>
<li><strong>User:</strong> This updates to all new apis for user and user groups</li>
<li><strong>Schema:</strong> All User/UserGroup calls switch to api2</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/compare/v11.0.7...v11.0.8">11.0.8</a> (2024-02-09)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/8688c2953673d8854661a9cd8ddbb447f2498787">8688c29</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/compare/v11.0.6...v11.0.7">11.0.7</a> (2024-02-09)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/25141a375c0d2303ce27a4daacaa92071ad70ba1">25141a3</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/compare/v11.0.5...v11.0.6">11.0.6</a> (2024-02-09)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Schema:</strong> update to v13.2.2 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/40f7bd39c1b66799d935c059cb6cd4c2608d34e6">40f7bd3</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/compare/v11.0.4...v11.0.5">11.0.5</a> (2024-02-08)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/2aed82b99ec494ed72d69c2e0d4b00b62d01641b">2aed82b</a>)</li>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/e0fb2e8067320989082835a72c2c9bb7f7edb99f">e0fb2e8</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/compare/v11.0.3...v11.0.4">11.0.4</a> (2024-02-07)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>cRCA:</strong> update corrects fetching issue related to the recent nodeId removal for orgs (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/4a15ac9eecde0b469f9af7fc093ac00db4228f30">4a15ac9</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/compare/v11.0.2...v11.0.3">11.0.3</a> (2024-02-06)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/15a4b9ba5cba2f9a28dd1d4d0698b9d9e003289a">15a4b9b</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/compare/v11.0.1...v11.0.2">11.0.2</a> (2024-02-03)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/031b34b6f31e08d930312a6bf3006e7a65b42fac">031b34b</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/compare/v11.0.0...v11.0.1">11.0.1</a> (2024-02-03)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Schema:</strong> update to v13.2.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/d0fe395b0ed3281d9247f60b231d35fce1f9d6ed">d0fe395</a>)</li>
</ul>
<h1><a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/compare/v10.0.11...v11.0.0">11.0.0</a> (2024-02-02)</h1>
<h3>Features</h3>
<ul>
<li><strong>Delivery matrix:</strong> now links to critical inventory and chicklet view; includes hardware actualization (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/4c2a476c78c01379b5be951a9848f55847ac4138">4c2a476</a>)</li>
</ul>
<h3>BREAKING CHANGES</h3>
<ul>
<li><strong>Delivery matrix:</strong> Hardware actualization</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/compare/v10.0.10...v10.0.11">10.0.11</a> (2024-02-02)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Schema:</strong> update to v13.1.3 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/813c1208522afd36a6813fa8f0cf6ba3dd9914bc">813c120</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/compare/v10.0.9...v10.0.10">10.0.10</a> (2024-02-01)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Schema:</strong> update to v13.1.2 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/518f34963a1d698e729e4979fcff6539576ff24c">518f349</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/compare/v10.0.8...v10.0.9">10.0.9</a> (2024-02-01)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/4cf2e6be93dac7129cf2d5ae7c9273feeb5a5683">4cf2e6b</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/compare/v10.0.7...v10.0.8">10.0.8</a> (2024-02-01)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Schema:</strong> update to v13.1.1 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/060928aadd3b818e19bd7416bbf489ffae683afc">060928a</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/compare/v10.0.6...v10.0.7">10.0.7</a> (2024-01-31)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Schema:</strong> update to v13.1.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/17cec48a5ace8ed938a4726e5155251c43daa223">17cec48</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/compare/v10.0.5...v10.0.6">10.0.6</a> (2024-01-26)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Schema:</strong> update to v12.13.5 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/3317f4a0ca1ea07906a152b29a468577c5182753">3317f4a</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/compare/v10.0.4...v10.0.5">10.0.5</a> (2024-01-24)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Schema:</strong> update to v12.13.4 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/cb1d4bd37d94c4a306105f5c8bf3cbb259ea51d5">cb1d4bd</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/compare/v10.0.3...v10.0.4">10.0.4</a> (2024-01-24)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>LinkType:</strong> made the LinkTypes per context (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/ab95533bc7603df059a703af3b88677107a63f28">ab95533</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/compare/v10.0.2...v10.0.3">10.0.3</a> (2024-01-23)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/c6811708dede135984ec12583bda2040d4e3645c">c681170</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/compare/v10.0.1...v10.0.2">10.0.2</a> (2024-01-22)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/a8554d86817fb2c1138cea04ec935aea3924d018">a8554d8</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/compare/v10.0.0...v10.0.1">10.0.1</a> (2024-01-22)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Schema:</strong> update to v12.13.2 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/5a3555828b9db53868ac63c88b596cdc0979690a">5a35558</a>)</li>
</ul>
<h1><a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/compare/v9.16.0...v10.0.0">10.0.0</a> (2024-01-22)</h1>
<h3>Features</h3>
<ul>
<li><strong>Organizations:</strong> moved to api2 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/a864d75f0b1fe91fff4eb85e5a84c3b648c79aca">a864d75</a>)</li>
</ul>
<h3>BREAKING CHANGES</h3>
<ul>
<li><strong>Organizations:</strong> Organization now on apiv2</li>
</ul>
<h1><a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/compare/v9.15.12...v9.16.0">9.16.0</a> (2024-01-19)</h1>
<h3>Features</h3>
<ul>
<li><strong>Delivery Matrix Item:</strong> Replace hardware info section with standardized form to improve data processing elves. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/bf4ff10f353fabb53d24cea0c81b58d9515e5e3a">bf4ff10</a>)</li>
<li><strong>Pending approvals:</strong> Gives the user the ability to quickly see the status of approvals throughout the system. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/7c92aac8016cdee42f9f6ccbb014bb9938843faa">7c92aac</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/compare/v9.15.11...v9.15.12">9.15.12</a> (2024-01-19)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/28c6ffd07916a173629dfcf863921ba550859032">28c6ffd</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/compare/v9.15.10...v9.15.11">9.15.11</a> (2024-01-19)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/30f099d407de45ad901de7be43321fe8ac9192d3">30f099d</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/compare/v9.15.9...v9.15.10">9.15.10</a> (2024-01-18)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/4ff7fb2da76b7282fef8e8cad231a736f2b1402f">4ff7fb2</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/compare/v9.15.8...v9.15.9">9.15.9</a> (2024-01-18)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Schema:</strong> update to v12.11.2 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/cf7e42bac06afc19304029947c09bdeb21fe2a86">cf7e42b</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/compare/v9.15.7...v9.15.8">9.15.8</a> (2024-01-16)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Schema:</strong> update to v12.11.1 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/80fb2bec78b283704fbf81c87e13363cdcd29307">80fb2be</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/compare/v9.15.6...v9.15.7">9.15.7</a> (2024-01-16)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Location:</strong> apiv2 move (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/93ca48b4f6d37461ed09d031c14d7e674d39735e">93ca48b</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/compare/v9.15.5...v9.15.6">9.15.6</a> (2024-01-16)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/c9633e96f1a4172239bdd9f1a245fd0f809fc01f">c9633e9</a>)</li>
<li><strong>User groups:</strong> update corrects a slight miscalculation leading to structural modular damage. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/9376fdbaca5698711be86bcca92aeb6d532851e4">9376fdb</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/compare/v9.15.4...v9.15.5">9.15.5</a> (2024-01-12)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Logging:</strong> updated the logging controller (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/ee551fa372c2a2835e2a892c918e96625478bd03">ee551fa</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/compare/v9.15.3...v9.15.4">9.15.4</a> (2024-01-11)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Schema:</strong> update to v12.10.1 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/056dce341c2e7056e2d689c6c4b4cb96f7750782">056dce3</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/compare/v9.15.2...v9.15.3">9.15.3</a> (2024-01-11)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Database:</strong> update (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/e403431806d9979b749853e275bad8e0203334fc">e403431</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/compare/v9.15.1...v9.15.2">9.15.2</a> (2024-01-11)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Schema:</strong> update to v12.9.1 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/94d6a8ed37513efa162b08efb6b1991cf1ec2492">94d6a8e</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/compare/v9.15.0...v9.15.1">9.15.1</a> (2024-01-10)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/2eb738d2ccdd2e244bae70ff4504e9dd15091dfa">2eb738d</a>)</li>
</ul>
<h1><a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/compare/v9.14.1...v9.15.0">9.15.0</a> (2024-01-10)</h1>
<h3>Features</h3>
<ul>
<li><strong>Schema:</strong> update to v12.9.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/492f9126968f370d17b490a548f15032cbaed763">492f912</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/compare/v9.14.0...v9.14.1">9.14.1</a> (2024-01-09)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/296ce7469af5aaf9488756f27ff09639ad77408a">296ce74</a>)</li>
</ul>
<h1><a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/compare/v9.13.11...v9.14.0">9.14.0</a> (2024-01-09)</h1>
<h3>Features</h3>
<ul>
<li><strong>Schema:</strong> update to v12.8.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/09d5f38e756ba777f6087a43c7091b30ed3b22b7">09d5f38</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/compare/v9.13.10...v9.13.11">9.13.11</a> (2024-01-04)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Schema:</strong> update to v12.6.5 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/067155c5bad1ef1ebb1d20d61d8c2ae389c5f69f">067155c</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/compare/v9.13.9...v9.13.10">9.13.10</a> (2024-01-02)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Schema:</strong> update to v12.6.3 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/2d4d9bea338778c96280ec70bac113096d7a8386">2d4d9be</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/compare/v9.13.8...v9.13.9">9.13.9</a> (2024-01-02)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Schema:</strong> update to v12.6.1 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/cb78faaf9fc7c7d5656bf1fb834c876f63e65941">cb78faa</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/compare/v9.13.7...v9.13.8">9.13.8</a> (2023-12-19)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Request Access:</strong> Remove unused imports. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/cd7e4761f39f939b7990b3b000e09b9913fc583e">cd7e476</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/compare/v9.13.6...v9.13.7">9.13.7</a> (2023-12-19)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>TheCreateMenu:</strong> Add User added to create menu (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/102b7bc4f52108dbea25a5de81b9228e3ebc103e">102b7bc</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/compare/v9.13.5...v9.13.6">9.13.6</a> (2023-12-13)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/e5709766c30090176d61d1c46b483fd4912ef72b">e570976</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/compare/v9.13.4...v9.13.5">9.13.5</a> (2023-12-12)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Schema:</strong> update to v12.5.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/03a330df5402efda6d0eb0b5a60fd445c552f33e">03a330d</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/compare/v9.13.3...v9.13.4">9.13.4</a> (2023-12-07)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/12a74080ec906cd68371e6a40493b9815b8f3529">12a7408</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/compare/v9.13.2...v9.13.3">9.13.3</a> (2023-12-06)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/22feba8eb717ebebe18042b68e78f39cea7ff53d">22feba8</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/compare/v9.13.1...v9.13.2">9.13.2</a> (2023-12-04)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Delivery Matrix item:</strong> added needType requirement for save. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/435fe1ef1359dae64f19c43ffc16f1af558d50c2">435fe1e</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/compare/v9.13.0...v9.13.1">9.13.1</a> (2023-12-04)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Schema:</strong> update to v12.4.1 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/6dbd611846e9017276e52cb0790b1f5d6bb3ea9b">6dbd611</a>)</li>
</ul>
<h1><a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/compare/v9.12.12...v9.13.0">9.13.0</a> (2023-12-04)</h1>
<h3>Features</h3>
<ul>
<li><strong>Stage Separation:</strong> Separating concerns from contract specific requirements (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/a1c9fad133c1dd82936928553bd3d68374a9aac8">a1c9fad</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/compare/v9.12.11...v9.12.12">9.12.12</a> (2023-11-30)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Title:</strong> update (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/1b36e65be9549cee24177dc09dc57b64c5b9b44b">1b36e65</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/compare/v9.12.10...v9.12.11">9.12.11</a> (2023-11-29)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Docs:</strong> delivery matrix types update (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/93584094090f86aa6456e538bdf88aa4f72e5a8f">9358409</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/compare/v9.12.9...v9.12.10">9.12.10</a> (2023-11-29)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Schema:</strong> update to v12.3.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/60732ccd68ecd650290238bd52dd09c17dc45b13">60732cc</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/compare/v9.12.8...v9.12.9">9.12.9</a> (2023-11-27)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Confirmation dialog:</strong> fixed issue where No button failed in certain cases (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/d932a85ced605a7713bf151bc4e98e1693634dbf">d932a85</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/compare/v9.12.7...v9.12.8">9.12.8</a> (2023-11-14)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Build:</strong> update (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/820d410f16cb2b26e39bbc41d45574fb62ebb5fe">820d410</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/compare/v9.12.6...v9.12.7">9.12.7</a> (2023-11-14)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Schema:</strong> update to v12.0.1 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/9f619987aaf11d154e58e2c1204840f88cc5d54a">9f61998</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/compare/v9.12.5...v9.12.6">9.12.6</a> (2023-11-14)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/423777c7d1644fb4e819dabeac4526fb4da207f1">423777c</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/compare/v9.12.4...v9.12.5">9.12.5</a> (2023-11-13)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Schema:</strong> update to v12.0.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/47493bec40cfa799f8d9bba295db59d567647bd9">47493be</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/compare/v9.12.3...v9.12.4">9.12.4</a> (2023-11-09)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Sharing:</strong> more components (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/7d44e4c01dc96e1c7bb169ec12c802343daa9d57">7d44e4c</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/compare/v9.12.2...v9.12.3">9.12.3</a> (2023-11-09)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Deployment:</strong> updates (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/fb7a4f452994638651bd62b5b4af2989cca4c017">fb7a4f4</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/compare/v9.12.1...v9.12.2">9.12.2</a> (2023-11-09)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Schema:</strong> update to v11.19.5 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/8468ea0db23b6e6e62f2fc81edd6fd241b46f2eb">8468ea0</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/compare/v9.12.0...v9.12.1">9.12.1</a> (2023-11-09)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Schema:</strong> update to v11.19.3 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/43a2eae9e85d2dde2561e337b9997eae150e5235">43a2eae</a>)</li>
</ul>
<h1><a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/compare/v9.11.5...v9.12.0">9.12.0</a> (2023-11-08)</h1>
<h3>Features</h3>
<ul>
<li><strong>Sharing:</strong> November is sharing month. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/c2e0665c85791b5c998806fb81d845f53e7bf8f9">c2e0665</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/compare/v9.11.4...v9.11.5">9.11.5</a> (2023-10-30)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Dep:</strong> update (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/ff843e72df220496bbb9fe583e91e29abee3ce95">ff843e7</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/compare/v9.11.3...v9.11.4">9.11.4</a> (2023-10-30)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Workflows:</strong> spreading the love. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/501bb794c4a1cdb96ffe53f3797b7a32f0d3f15a">501bb79</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/compare/v9.11.2...v9.11.3">9.11.3</a> (2023-10-26)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Schema:</strong> update to v11.18.4 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/3fe62d0e284a6fc56c60b05765a0caf219ca9fd5">3fe62d0</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/compare/v9.11.1...v9.11.2">9.11.2</a> (2023-10-24)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Schema:</strong> update to v11.18.1 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/d50f200b1dc78732e1ef37e0b4543c013a9cfa2d">d50f200</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/compare/v9.11.0...v9.11.1">9.11.1</a> (2023-10-19)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Deployment:</strong> update (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/b2980a6af68890476ffb72828c50d65007b8828d">b2980a6</a>)</li>
</ul>
<h1><a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/compare/v9.10.1...v9.11.0">9.11.0</a> (2023-10-19)</h1>
<h3>Features</h3>
<ul>
<li><strong>Sign-in flow:</strong> route to correct URL after authenticating (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/224613379a6c30a09246a5d8afad7b65422f31cb">2246133</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/compare/v9.10.0...v9.10.1">9.10.1</a> (2023-10-18)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Deployment:</strong> update (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/e71cd6647bbfe42b096cf22b6486b4f1108645b0">e71cd66</a>)</li>
</ul>
<h1><a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/compare/v9.9.0...v9.10.0">9.10.0</a> (2023-10-13)</h1>
<h3>Features</h3>
<ul>
<li><strong>Import jf288:</strong> Improvements to import spreadsheet (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/b51e2e8793c0c65f0fa924a63f72f1db1fd9f9d6">b51e2e8</a>)</li>
<li><strong>Import jf288:</strong> Improvements to import spreadsheet (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/8d9dbe11b5344af97bbc72886e13cf74c3d8225e">8d9dbe1</a>)</li>
</ul>
<h1><a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/compare/v9.8.0...v9.9.0">9.9.0</a> (2023-10-12)</h1>
<h3>Features</h3>
<ul>
<li><strong>Public assets:</strong> swapped locations. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/c5af3a4e50eaf9f5afff1affc8f848e0dcc37d60">c5af3a4</a>)</li>
</ul>
<h1><a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/compare/v9.7.3...v9.8.0">9.8.0</a> (2023-10-12)</h1>
<h3>Features</h3>
<ul>
<li><strong>Tooltips:</strong> improved legibility and performance (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/e3ebe5a083073ac41c16e1df8d5a14e17ff82e72">e3ebe5a</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/compare/v9.7.2...v9.7.3">9.7.3</a> (2023-10-06)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Delivery Matrix:</strong> update styling (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/570fc460b434e439b25150c7fbd24972e07cb746">570fc46</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/compare/v9.7.1...v9.7.2">9.7.2</a> (2023-10-06)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Delivery Matrix:</strong> increased the size of the description col (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/4dc36c870b00c600de66a298dc2b41bbf2720f7c">4dc36c8</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/compare/v9.7.0...v9.7.1">9.7.1</a> (2023-10-06)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Delivery Matrix:</strong> updated and standardized logic (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/cba29cfc26d8d99fc786e093a03250e34073ad67">cba29cf</a>)</li>
</ul>
<h1><a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/compare/v9.6.22...v9.7.0">9.7.0</a> (2023-10-06)</h1>
<h3>Features</h3>
<ul>
<li><strong>Delivery Matrix:</strong> ability to add an attachment upon completion (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/bae38667ee4babcb9c254fe83fe7e832604af1ae">bae3866</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/compare/v9.6.21...v9.6.22">9.6.22</a> (2023-10-05)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Delivery Matrix:</strong> update search/manage (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/a0b595602319180d3dec6103f7c5746a51703752">a0b5956</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/compare/v9.6.20...v9.6.21">9.6.21</a> (2023-10-04)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Schema:</strong> update to v11.17.5 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/4f4d5e6987041270c044b024f4ab70cddf814cc5">4f4d5e6</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/compare/v9.6.19...v9.6.20">9.6.20</a> (2023-10-04)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Developer:</strong> integration commit (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/b7dfc594b2f8e0e96c050a52d78819913eb045be">b7dfc59</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/compare/v9.6.18...v9.6.19">9.6.19</a> (2023-10-02)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>DeliveryMatrixItemCreateView:</strong> Fixed grid breakpoint for organization select to support longer organization names. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/6fda689d33030d3b7fbc813a6cf795da4ee21b4b">6fda689</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/compare/v9.6.17...v9.6.18">9.6.18</a> (2023-09-28)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Deployment update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/f4c0f6a5f1993cd58f63724dd6921dc0c2323c60">f4c0f6a</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/compare/v9.6.16...v9.6.17">9.6.17</a> (2023-09-26)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Schema:</strong> update to v11.17.3 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/696539639e942edab125a90e821b4750713329cb">6965396</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/compare/v9.6.15...v9.6.16">9.6.16</a> (2023-09-21)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Deployment update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/148b717b8b30876cc4cd11668a826f07551b4583">148b717</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/compare/v9.6.14...v9.6.15">9.6.15</a> (2023-09-21)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Attachments:</strong> move delete functionality from modal to inline of the table (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/e27008e6d236b0673a9b910d3c9ffca39b4c3751">e27008e</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/compare/v9.6.13...v9.6.14">9.6.14</a> (2023-09-19)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Delivery Matrix:</strong> allow users to link to page with no filters (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/e728b54fe4236b034dcce3b790b2a7f4f7bc692f">e728b54</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/compare/v9.6.12...v9.6.13">9.6.13</a> (2023-09-19)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Deployment update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/aae5cc09fb2d4efcf8f8c0b0b01d8870e3d29745">aae5cc0</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/compare/v9.6.11...v9.6.12">9.6.12</a> (2023-09-15)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Delivery Matrix Item:</strong> updated required fields on create and edit on manage page (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/9f30033e269604d662087189c127e343695c3f1e">9f30033</a>)</li>
<li><strong>Delivery Matrix Search:</strong> added search params to url (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/c09ed2d1047a09de9837616f99c40de6bb95eb82">c09ed2d</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/compare/v9.6.10...v9.6.11">9.6.11</a> (2023-09-14)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Deployment update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/3fc0d9e74fd3697d6efc475e10a1c3a0c460d376">3fc0d9e</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/compare/v9.6.9...v9.6.10">9.6.10</a> (2023-09-14)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Approvals tab:</strong> selecting no closes the confirmation popup (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/167166b2ae9bc7739e12763c84f3ee1e664241ff">167166b</a>)</li>
<li><strong>Fracas Creation:</strong> Required form fields are now highlighted in red. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/05128353053d67f597f1b03dabf6b5e5f883fcfd">0512835</a>)</li>
<li><strong>User Access:</strong> updated check for user access to be based on isProprietary (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/3dadb86db404c5459f7a90fef11f3b25aac55e62">3dadb86</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/compare/v9.6.8...v9.6.9">9.6.9</a> (2023-09-13)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Schema:</strong> update to v11.17.2 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/57798288e62678f5c3df2564d64205311d26aa85">5779828</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/compare/v9.6.7...v9.6.8">9.6.8</a> (2023-09-12)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Schema:</strong> update to v11.17.1 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/816bedfff0d5169591686a01c903f598e3572390">816bedf</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/compare/v9.6.6...v9.6.7">9.6.7</a> (2023-09-08)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>WorkOrderStep Edit:</strong> populate already selected hardware and tools on edit (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/85f442738dc9e8b92f9b2241bf559aab11f46560">85f4427</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/compare/v9.6.5...v9.6.6">9.6.6</a> (2023-09-08)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/4114e0d8d1c41e204bbe09809259473b3eca854a">4114e0d</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/compare/v9.6.4...v9.6.5">9.6.5</a> (2023-09-07)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Schema:</strong> update to v11.17.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/1b18c40730783b0afed6171959bc57adbdcfe427">1b18c40</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/compare/v9.6.3...v9.6.4">9.6.4</a> (2023-09-07)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/e5674135f9e7b00806b30d803b4b2925361171a4">e567413</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/compare/v9.6.2...v9.6.3">9.6.3</a> (2023-09-07)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>DR:</strong> updated manage page header (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/546f7b105121c36fca472cd721036fcc2d2b95df">546f7b1</a>)</li>
<li><strong>EZT:</strong> updated manage page header (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/1d4934688bd2faca2a8e4f1e91dee94c7e992aa1">1d49346</a>)</li>
<li><strong>FRACAS:</strong> updated manage page header (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/8cfaec90a4fef1a2634e48d3dbd46f6dc72cd7e6">8cfaec9</a>)</li>
<li><strong>Home:</strong> update ui to be standards compliant (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/bb57eb6ce2ada183fa52a195cf8c662d6faac279">bb57eb6</a>)</li>
<li><strong>RCA:</strong> updated manage page header (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/5a981577f7029c9f81961dabb28d262993041448">5a98157</a>)</li>
<li><strong>TPS:</strong> updated manage page header (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/426821247dbcc506e43b464830391a0453cdc4c7">4268212</a>)</li>
<li><strong>Workflow:</strong> updated manage page header (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/78ed93ebe9df353196035c478de7de6d7ba7b2bb">78ed93e</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/compare/v9.6.1...v9.6.2">9.6.2</a> (2023-09-07)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Design Standards:</strong> update to newly formed standards (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/6232c39a8466a14bdad981a8635f61d50dcf0cff">6232c39</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/compare/v9.6.0...v9.6.1">9.6.1</a> (2023-09-06)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>TheSideNav:</strong> Moved changlog above external links. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/b689a1a5055d6747c10de004c03163f3bce352e4">b689a1a</a>)</li>
</ul>
<h1><a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/compare/v9.5.31...v9.6.0">9.6.0</a> (2023-09-05)</h1>
<h3>Features</h3>
<ul>
<li><strong>Delivery matrix item:</strong> users may now upload attachments to each Delivery Matrix Item. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/4b57cf5aba274538926b47a77d6f0ac2c4961a6e">4b57cf5</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/compare/v9.5.30...v9.5.31">9.5.31</a> (2023-09-01)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/c0efbc8054260d1fe5c4b194c72e92b87995fe03">c0efbc8</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/compare/v9.5.29...v9.5.30">9.5.30</a> (2023-08-31)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> ButtonMiniActionManage Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/17968a60ce6f8d8fa90370a40a3abf8a5def4304">17968a6</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/compare/v9.5.28...v9.5.29">9.5.29</a> (2023-08-31)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Tables:</strong> update interactions on reporting tables (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/4b60537a01f36a9a1fc80c2b68202560fbc27d7e">4b60537</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/compare/v9.5.27...v9.5.28">9.5.28</a> (2023-08-31)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Table:</strong> implementation for new ux for tables. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/d15f41053be2ef7c472da0e38485be9c08cc8063">d15f410</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/compare/v9.5.26...v9.5.27">9.5.27</a> (2023-08-31)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Side Nav:</strong> corrected ui. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/303a467e438f993170c5f638e2f1d4181f291713">303a467</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/compare/v9.5.25...v9.5.26">9.5.26</a> (2023-08-31)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Schema:</strong> update to v11.16.3 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/0f9726310bdb584524c8e7d580a2bd696971a6ef">0f97263</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/compare/v9.5.24...v9.5.25">9.5.25</a> (2023-08-30)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Schema:</strong> update to v11.16.2 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/567879f6fc7968127edab4eab843c2386e5ff2a1">567879f</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/compare/v9.5.23...v9.5.24">9.5.24</a> (2023-08-29)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Delivery Matrix:</strong> adding DIL to filters (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/643d94b5457205afcff238e8c2d5ef89348bbc45">643d94b</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/compare/v9.5.22...v9.5.23">9.5.23</a> (2023-08-29)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Schema:</strong> update to v11.16.1 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/5cad0bbe3d69a8d2678704fe2c03c69609246d16">5cad0bb</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/compare/v9.5.21...v9.5.22">9.5.22</a> (2023-08-27)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Schema:</strong> update to v111.16.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/a732c19ab585aca8b19808318ea251bffb093e6d">a732c19</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/compare/v9.5.20...v9.5.21">9.5.21</a> (2023-08-23)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/6927508528884a4a1c86d6e1b61c31e29da3ba28">6927508</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/compare/v9.5.19...v9.5.20">9.5.20</a> (2023-08-23)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Delivery Matrix:</strong> apply delivery date from completion modal (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/d422583859a2841a7e3f9e7459bd3321e74fefe4">d422583</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/compare/v9.5.18...v9.5.19">9.5.19</a> (2023-08-22)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Schema:</strong> update to v11.4.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/64528eb9779f53181f2e15d3a39c95e37b2ed46d">64528eb</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/compare/v9.5.17...v9.5.18">9.5.18</a> (2023-08-21)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Schema:</strong> update to v11.13.2 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/52ba5efaafbf4f4bb0a9cc41f45d994d2fc4e686">52ba5ef</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/compare/v9.5.16...v9.5.17">9.5.17</a> (2023-08-20)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Schema:</strong> update to v11.13.1 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/21cff1ed71f7f86e3ffa44151a0bf82a0b0bf6ea">21cff1e</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/compare/v9.5.15...v9.5.16">9.5.16</a> (2023-08-20)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Delivery Matrix:</strong> path to production fixes and enhancements (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/7d61011fc63c25d6f6e159e06393e9c1828a9567">7d61011</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/compare/v9.5.14...v9.5.15">9.5.15</a> (2023-08-17)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/b6e307667505188bffac3cfcb24f971cfbdc7163">b6e3076</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/compare/v9.5.13...v9.5.14">9.5.14</a> (2023-08-17)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/ac34b642b10cf5977d45f77d77f390a3eb2962db">ac34b64</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/compare/v9.5.12...v9.5.13">9.5.13</a> (2023-08-16)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Schema:</strong> update to v11.12.2 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/3aa02253c93ba43f304e6628e3ffaf2655d5f97b">3aa0225</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/compare/v9.5.11...v9.5.12">9.5.12</a> (2023-08-14)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Schema:</strong> update to v11.11.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/0191532d7c60ed5d9b186706215626c9653a0a36">0191532</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/compare/v9.5.10...v9.5.11">9.5.11</a> (2023-08-11)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Side navigation:</strong> added uniform styling. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/bb5b9201cd0fcb064a4e3ccf2b6be1eda8222a5c">bb5b920</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/compare/v9.5.9...v9.5.10">9.5.10</a> (2023-08-10)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/b83e53cb9d2004c34d3b2c0347d7528d441edfef">b83e53c</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/compare/v9.5.8...v9.5.9">9.5.9</a> (2023-08-10)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Delivery Matrix:</strong> fixed flash of error message on item manage pages (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/068e4d97aade0f09f70ba127aa9e4ffbe4551d07">068e4d9</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/compare/v9.5.7...v9.5.8">9.5.8</a> (2023-08-08)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>User permissions:</strong> updated to show a subset of the permissions based on context the user is in (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/3b19010bf40f1258d3cb89681f915abd3b9f2687">3b19010</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/compare/v9.5.6...v9.5.7">9.5.7</a> (2023-08-07)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/492e66f69261d38f68646de5866279d081f64b28">492e66f</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/compare/v9.5.5...v9.5.6">9.5.6</a> (2023-08-07)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Permissions:</strong> per context permissions. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/f59bd6d2d7a0b6c802d95cb078581ce4858c37e9">f59bd6d</a>)</li>
<li><strong>Schema:</strong> update to v11.10.5 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/5bdb63d1b38af5f592cf173fffc3a1432317d2e9">5bdb63d</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/compare/v9.5.4...v9.5.5">9.5.5</a> (2023-08-04)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Change:</strong> update corrects staging build (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/cef4bda7fdc4198167a3465fa49a1d2de7c75b06">cef4bda</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/compare/v9.5.3...v9.5.4">9.5.4</a> (2023-08-04)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/f1efd4a770e43b616567f303412021c65d9e8992">f1efd4a</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/compare/v9.5.2...v9.5.3">9.5.3</a> (2023-08-04)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> build update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/b33abadd55ac4f66c91d09120c52cef79a7ab402">b33abad</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/compare/v9.5.1...v9.5.2">9.5.2</a> (2023-08-03)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Schema:</strong> update to v11.10.4 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/139f19db8d8a1cf433df06e09f6daea3ded989ff">139f19d</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/compare/v9.5.0...v9.5.1">9.5.1</a> (2023-08-02)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Schema:</strong> update to v11.10.3 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/3e20d4e987f7839ace60abf5ff44df1714c43414">3e20d4e</a>)</li>
</ul>
<h1><a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/compare/v9.4.2...v9.5.0">9.5.0</a> (2023-08-01)</h1>
<h3>Features</h3>
<ul>
<li><strong>UI:</strong> added foundation for sticky table columns (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/08694a223ad8fc4aa05ae73becf3697baca41672">08694a2</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/compare/v9.4.1...v9.4.2">9.4.2</a> (2023-08-01)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/b7f394d9ca1c8563da7512e47d06f3dd0d425fb2">b7f394d</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/compare/v9.4.0...v9.4.1">9.4.1</a> (2023-07-27)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Date dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/c00f74ccd12cd014f3b485135dbe3c7f8658b802">c00f74c</a>)</li>
</ul>
<h1><a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/compare/v9.3.12...v9.4.0">9.4.0</a> (2023-07-27)</h1>
<h3>Features</h3>
<ul>
<li><strong>Delivery Matrix:</strong> metadata field updates (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/90579bf79da92226aabe3bd1fb9c0cb15aaa6cf0">90579bf</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/compare/v9.3.11...v9.3.12">9.3.12</a> (2023-07-27)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Schema:</strong> update to v11.9.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/5530479d251a77dc5fc655cea1b4e194c36888a4">5530479</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/compare/v9.3.10...v9.3.11">9.3.11</a> (2023-07-25)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Schema:</strong> update to v11.8.1 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/ee606431c4cd9e87d9b848a75cfe2770d444a535">ee60643</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/compare/v9.3.9...v9.3.10">9.3.10</a> (2023-07-24)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Branding:</strong> new tab bar icon (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/917675795eeca9f819d855f5ea6a56d67799602e">9176757</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/compare/v9.3.8...v9.3.9">9.3.9</a> (2023-07-21)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/92fc1c4685b1cb6e92101961ce87003854d8c6da">92fc1c4</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/compare/v9.3.7...v9.3.8">9.3.8</a> (2023-07-20)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>TheSideNav:</strong> Added admin link and admin route and import for admin dashboard. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/77726bf405f7c8a2096708fbdb88228d0d08f07b">77726bf</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/compare/v9.3.6...v9.3.7">9.3.7</a> (2023-07-19)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Footer:</strong> Updated user guide link to point to knowledge base. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/5e3582a14c193c9e181cf6ae30ee4f69aab20f00">5e3582a</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/compare/v9.3.5...v9.3.6">9.3.6</a> (2023-07-18)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/592a07727ce489df439616608d8dc129ae8ed88a">592a077</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/compare/v9.3.4...v9.3.5">9.3.5</a> (2023-07-18)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/2a022abd3512eead9fabe075e7674ab01244a1a7">2a022ab</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/compare/v9.3.3...v9.3.4">9.3.4</a> (2023-07-13)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Swagger:</strong> Change method of building swagger types for enums with numbers (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/f35a74fdd968753f5b3feb9174ad6bd410f24bd2">f35a74f</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/compare/v9.3.2...v9.3.3">9.3.3</a> (2023-07-13)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/ef865e971e144afd1a7a1b5ade7a99dd4b441712">ef865e9</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/compare/v9.3.1...v9.3.2">9.3.2</a> (2023-07-13)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Delivery Matrix:</strong> completion modal (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/c0131a3d6cc7537405c1d4d305b7928885823184">c0131a3</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/compare/v9.3.0...v9.3.1">9.3.1</a> (2023-07-10)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/2621ae37d5f3158ea4af72ae686dfa562ee3b6ed">2621ae3</a>)</li>
</ul>
<h1><a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/compare/v9.2.5...v9.3.0">9.3.0</a> (2023-07-10)</h1>
<h3>Features</h3>
<ul>
<li><strong>Delivery matrix:</strong> implemented numerous items received from user feedback (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/785b95cff9b444f9de16ede74f6614251e289ead">785b95c</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/compare/v9.2.4...v9.2.5">9.2.5</a> (2023-07-05)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Remove unused code. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/ffe6fea65d210bf5720bb591e26f27e68ee382ed">ffe6fea</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/compare/v9.2.3...v9.2.4">9.2.4</a> (2023-07-05)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/6c922b9cdfa06a32763178d61d2b49e2ac549689">6c922b9</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/compare/v9.2.2...v9.2.3">9.2.3</a> (2023-07-03)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Correct navigational problem. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/3d025659922c678f9231304aeb438ef5123dac92">3d02565</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/compare/v9.2.1...v9.2.2">9.2.2</a> (2023-06-29)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/cd1d0dc19042481b00cea7888ae7216a8a8c43c0">cd1d0dc</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/compare/v9.2.0...v9.2.1">9.2.1</a> (2023-06-27)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Contacts:</strong> added contacts routes. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/70f972029a203a76cd6462eccf64ff29fa967cc3">70f9720</a>)</li>
</ul>
<h1><a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/compare/v9.1.1...v9.2.0">9.2.0</a> (2023-06-27)</h1>
<h3>Features</h3>
<ul>
<li><strong>Schema:</strong> update to v10.7.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/7724917b7c7277ac812caefb7f66e5f7058d9a5b">7724917</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/compare/v9.1.0...v9.1.1">9.1.1</a> (2023-06-23)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>RCA Manage:</strong> updated pill text color for Process Escape and award fee write up period pills (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/c560abea26a81ac9b822315d863848be0dedae3e">c560abe</a>)</li>
</ul>
<h1><a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/compare/v9.0.11...v9.1.0">9.1.0</a> (2023-06-23)</h1>
<h3>Features</h3>
<ul>
<li><strong>Delivery Matrix:</strong> initial deployment of new feature (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/b24d542cec1c0042559802440d81546166e19c24">b24d542</a>)</li>
<li><strong>Documents Manage:</strong> added back to search and favorite icons to header (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/5900aa1b1e40d85a9e6bdad7fd29f0f0bf62f508">5900aa1</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/compare/v9.0.10...v9.0.11">9.0.11</a> (2023-06-20)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Schema:</strong> update to v10.5.2 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/19ec0751dae700f6a2671af84ced845e389f3eee">19ec075</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/compare/v9.0.9...v9.0.10">9.0.10</a> (2023-06-20)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> consistency update/ (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/bc701f64169be9840cfe866d80f2a14965542129">bc701f6</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/compare/v9.0.8...v9.0.9">9.0.9</a> (2023-06-19)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Schema:</strong> update to v10.5.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/57be8046da3b783a1df0f77bf9c28db1d5243fbd">57be804</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/compare/v9.0.7...v9.0.8">9.0.8</a> (2023-06-19)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/a3181f634d77c077f18511fbb1806fcbdbb9e7f3">a3181f6</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/compare/v9.0.6...v9.0.7">9.0.7</a> (2023-06-15)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/09a23817ac20826b485f2e4d1f9e48a457e3997a">09a2381</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/compare/v9.0.5...v9.0.6">9.0.6</a> (2023-06-15)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Navbar:</strong> toggle navbar items from user settings (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/139df3c3c517ea5135ce76364aa79e387a14d606">139df3c</a>)</li>
<li><strong>RCA Export:</strong> corrected display overflow issue (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/8d2f954555f766a77685f65ae088474739d287f5">8d2f954</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/compare/v9.0.4...v9.0.5">9.0.5</a> (2023-06-15)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/docs/commit/6956c4f8b0b2a2e9d1ad573ed6aa532231acefa8">6956c4f</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/docs/docs_frontend/compare/v9.0.3...v9.0.4">9.0.4</a> (2023-06-14)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Schema:</strong> update to v10.4.1 (<a href="https://gitlab.com/mri-technologies/cosmic/docs/docs_frontend/commit/651c784f2b4864b260a1a6360fc23bd00ddee812">651c784</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/docs/docs_frontend/compare/v9.0.2...v9.0.3">9.0.3</a> (2023-06-14)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Schema:</strong> update to v10.4.0 (<a href="https://gitlab.com/mri-technologies/cosmic/docs/docs_frontend/commit/c85dd386ae0628624e8d577efd15cae3cd627a60">c85dd38</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/docs/docs_frontend/compare/v9.0.1...v9.0.2">9.0.2</a> (2023-06-14)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Schema:</strong> update to v10.3.0 (<a href="https://gitlab.com/mri-technologies/cosmic/docs/docs_frontend/commit/c33fbab7e32ddac19095f84563973c2644bc8bd3">c33fbab</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/docs/docs_frontend/compare/v9.0.0...v9.0.1">9.0.1</a> (2023-06-14)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>RCA:</strong> corrected issue when saving Risk information (<a href="https://gitlab.com/mri-technologies/cosmic/docs/docs_frontend/commit/2448122ac9bfbaa7b1c2b32d4be7306f9985b9f7">2448122</a>)</li>
</ul>
<h1><a href="https://gitlab.com/mri-technologies/cosmic/docs/docs_frontend/compare/v8.21.5...v9.0.0">9.0.0</a> (2023-06-13)</h1>
<h3>Features</h3>
<ul>
<li><strong>Change Sets:</strong> Update to use id. (<a href="https://gitlab.com/mri-technologies/cosmic/docs/docs_frontend/commit/7147ddf0213e379bfc94279f93679592b3344899">7147ddf</a>)</li>
</ul>
<h3>BREAKING CHANGES</h3>
<ul>
<li><strong>Change Sets:</strong> update.</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/docs/docs_frontend/compare/v8.21.4...v8.21.5">8.21.5</a> (2023-06-13)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/docs/docs_frontend/commit/b2fef4c15fa88b52b7a16a33267f6b93c33ba29e">b2fef4c</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/docs/docs_frontend/compare/v8.21.3...v8.21.4">8.21.4</a> (2023-06-13)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Schema:</strong> update to v10.2.2 (<a href="https://gitlab.com/mri-technologies/cosmic/docs/docs_frontend/commit/4afcc1c7ac74385173ea114752624776d50a9541">4afcc1c</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/docs/docs_frontend/compare/v8.21.2...v8.21.3">8.21.3</a> (2023-06-13)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Navbar:</strong> added Favorites link in navbar to quickly view favorites (<a href="https://gitlab.com/mri-technologies/cosmic/docs/docs_frontend/commit/14c4e28d9491b2542bf32c01b1b4b5b43337025e">14c4e28</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/docs/docs_frontend/compare/v8.21.1...v8.21.2">8.21.2</a> (2023-06-08)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Schema:</strong> update to v10.2.1 (<a href="https://gitlab.com/mri-technologies/cosmic/docs/docs_frontend/commit/4e35b0a2af582140dfa5e14ab769f2946d10b8a8">4e35b0a</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/docs/docs_frontend/compare/v8.21.0...v8.21.1">8.21.1</a> (2023-06-08)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Schema:</strong> update to v10.2.0 (<a href="https://gitlab.com/mri-technologies/cosmic/docs/docs_frontend/commit/260818f5adce29525c90adc7e9613664e3001005">260818f</a>)</li>
</ul>
<h1><a href="https://gitlab.com/mri-technologies/cosmic/docs/docs_frontend/compare/v8.20.2...v8.21.0">8.21.0</a> (2023-06-06)</h1>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Schema:</strong> update to v10.1.0 (<a href="https://gitlab.com/mri-technologies/cosmic/docs/docs_frontend/commit/1d842eed99d28312a0cf2a9534f594edf381752e">1d842ee</a>)</li>
</ul>
<h3>Features</h3>
<ul>
<li><strong>Schema:</strong> update to v10.0.1 (<a href="https://gitlab.com/mri-technologies/cosmic/docs/docs_frontend/commit/6a984aa798cd33eab69ccdf96447fe19a35120dc">6a984aa</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/docs/docs_frontend/compare/v8.20.1...v8.20.2">8.20.2</a> (2023-06-02)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Schema:</strong> update to v9.1.2 (<a href="https://gitlab.com/mri-technologies/cosmic/docs/docs_frontend/commit/add8dcd39913357c8ae5794c7724b56364a6d124">add8dcd</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/docs/docs_frontend/compare/v8.20.0...v8.20.1">8.20.1</a> (2023-06-01)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/docs/docs_frontend/commit/d79d91ae7e908db6d9762ae5e0b616c18b9e5b1c">d79d91a</a>)</li>
</ul>
<h1><a href="https://gitlab.com/mri-technologies/cosmic/docs/docs_frontend/compare/v8.19.11...v8.20.0">8.20.0</a> (2023-06-01)</h1>
<h3>Features</h3>
<ul>
<li><strong>Schema:</strong> update to v9.1.1 (<a href="https://gitlab.com/mri-technologies/cosmic/docs/docs_frontend/commit/581cbeb5588d4e6e6b1c26a3bb75b4700b2fb1e3">581cbeb</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/docs/docs_frontend/compare/v8.19.10...v8.19.11">8.19.11</a> (2023-05-27)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/docs/docs_frontend/commit/81675c1bb6ae80f4d37e6d61b313223ff0ca621e">81675c1</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/docs/docs_frontend/compare/v8.19.9...v8.19.10">8.19.10</a> (2023-05-26)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Delivery Matrix:</strong> feature scaffolding (<a href="https://gitlab.com/mri-technologies/cosmic/docs/docs_frontend/commit/956497a26e4c6e4e2110d29b75ca8449382fd560">956497a</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/docs/docs_frontend/compare/v8.19.8...v8.19.9">8.19.9</a> (2023-05-23)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/docs/docs_frontend/commit/31dbd251f252b52d410f3a8ec0c76521e8c0a2d4">31dbd25</a>)</li>
<li><strong>Workflows:</strong> corrects task count in the radial progress chart (<a href="https://gitlab.com/mri-technologies/cosmic/docs/docs_frontend/commit/51c0ff9d1f9cd5851fe17ce6b14f7fd9a469234b">51c0ff9</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/docs/docs_frontend/compare/v8.19.7...v8.19.8">8.19.8</a> (2023-05-23)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/docs/docs_frontend/commit/1ca40ceba7b2f5d6eb3f222bafd91d8b04151283">1ca40ce</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/docs/docs_frontend/compare/v8.19.6...v8.19.7">8.19.7</a> (2023-05-22)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/docs/docs_frontend/commit/880b75e01cbdc002980bb2fb7437d6f6b3cade77">880b75e</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/docs/docs_frontend/compare/v8.19.5...v8.19.6">8.19.6</a> (2023-05-22)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Workflows:</strong> correct issue when starting a new workflow (<a href="https://gitlab.com/mri-technologies/cosmic/docs/docs_frontend/commit/2f6ae233bf330be4fa8dd8c0a32956828bcf446c">2f6ae23</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/docs/docs_frontend/compare/v8.19.4...v8.19.5">8.19.5</a> (2023-05-22)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/docs/docs_frontend/commit/d279b2c8dbe124f69922c2c0b694e623cd6b073a">d279b2c</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/docs/docs_frontend/compare/v8.19.3...v8.19.4">8.19.4</a> (2023-05-18)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/docs/docs_frontend/commit/ef2bf7bfb3aab7846c2f38e982d1ee740e9a24c2">ef2bf7b</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/docs/docs_frontend/compare/v8.19.2...v8.19.3">8.19.3</a> (2023-05-18)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>TheFooter:</strong> Fixed No Fear Act link. (<a href="https://gitlab.com/mri-technologies/cosmic/docs/docs_frontend/commit/52160d9ccb5097b4226c1bc8ceaad81dd4410434">52160d9</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/docs/docs_frontend/compare/v8.19.1...v8.19.2">8.19.2</a> (2023-05-17)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/docs/docs_frontend/commit/362d6d779536f81a8cba5786e23acb82c6817d1f">362d6d7</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/docs/docs_frontend/compare/v8.19.0...v8.19.1">8.19.1</a> (2023-05-15)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/docs/docs_frontend/commit/dc657c05c05e62f3d9004d24c376bf12d65a4c9c">dc657c0</a>)</li>
</ul>
<h1><a href="https://gitlab.com/mri-technologies/cosmic/docs/docs_frontend/compare/v8.18.4...v8.19.0">8.19.0</a> (2023-05-15)</h1>
<h3>Features</h3>
<ul>
<li><strong>Award Fee Periods:</strong> moved from db to client enum (<a href="https://gitlab.com/mri-technologies/cosmic/docs/docs_frontend/commit/8d2185ef1c08bb85fa876406649eeb3e67822f1f">8d2185e</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/docs/docs_frontend/compare/v8.18.3...v8.18.4">8.18.4</a> (2023-05-10)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/docs/docs_frontend/commit/dfce1fe4ad02724f8b11f5e2a2a9fcbff3648eb1">dfce1fe</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/docs/docs_frontend/compare/v8.18.2...v8.18.3">8.18.3</a> (2023-05-09)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/docs/docs_frontend/commit/83be4484183988df7d14fc1db96da75d07e008bd">83be448</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/docs/docs_frontend/compare/v8.18.1...v8.18.2">8.18.2</a> (2023-05-08)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/docs/docs_frontend/commit/93fa5880352bf08fbb732d7caee116b8ac1421ad">93fa588</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/docs/docs_frontend/compare/v8.18.0...v8.18.1">8.18.1</a> (2023-05-08)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/docs/docs_frontend/commit/065d39288df930313f8ba8498a7b3d512cce74b4">065d392</a>)</li>
</ul>
<h1><a href="https://gitlab.com/mri-technologies/cosmic/docs/docs_frontend/compare/v8.17.0...v8.18.0">8.18.0</a> (2023-05-07)</h1>
<h3>Features</h3>
<ul>
<li><strong>Schema:</strong> update to v7.0.0 (<a href="https://gitlab.com/mri-technologies/cosmic/docs/docs_frontend/commit/9c3042ee48ce490cb05eb1639a1a8daad93ec096">9c3042e</a>)</li>
</ul>
<h1><a href="https://gitlab.com/mri-technologies/cosmic/docs/docs_frontend/compare/v8.16.2...v8.17.0">8.17.0</a> (2023-05-07)</h1>
<h3>Features</h3>
<ul>
<li><strong>Schema:</strong> update to v7.0.0 (<a href="https://gitlab.com/mri-technologies/cosmic/docs/docs_frontend/commit/172a59e57bfc5eb5a77916d150b79b34901cb894">172a59e</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/docs/docs_frontend/compare/v8.16.1...v8.16.2">8.16.2</a> (2023-05-05)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/docs/docs_frontend/commit/54668f7c7a2d7bf0402f28d3d7970f814abe3158">54668f7</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/docs/docs_frontend/compare/v8.16.0...v8.16.1">8.16.1</a> (2023-05-02)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/docs/docs_frontend/commit/5c966d2ab140088ff87d23aa90a28971d9957254">5c966d2</a>)</li>
</ul>
<h1><a href="https://gitlab.com/mri-technologies/cosmic/docs/docs_frontend/compare/v8.15.6...v8.16.0">8.16.0</a> (2023-04-27)</h1>
<h3>Features</h3>
<ul>
<li><strong>Schema:</strong> update to v6.3.0 (<a href="https://gitlab.com/mri-technologies/cosmic/docs/docs_frontend/commit/006d762839b05b0a988d7ed44588f0e22847fa4a">006d762</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/docs/docs_frontend/compare/v8.15.5...v8.15.6">8.15.6</a> (2023-04-27)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Schema:</strong> update to v6.2.1 (<a href="https://gitlab.com/mri-technologies/cosmic/docs/docs_frontend/commit/928de4b8d163b2e68a5dcacf2417d02bdb0694dd">928de4b</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/docs/docs_frontend/compare/v8.15.4...v8.15.5">8.15.5</a> (2023-04-27)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/docs/docs_frontend/commit/ab33057c0b18284f3a10c86d0ee93faba290d1dd">ab33057</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/docs/docs_frontend/compare/v8.15.3...v8.15.4">8.15.4</a> (2023-04-27)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Schema:</strong> update to v6.2.0 (<a href="https://gitlab.com/mri-technologies/cosmic/docs/docs_frontend/commit/f59f1716943bc8385978191080af23fe1092cd52">f59f171</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/docs/docs_frontend/compare/v8.15.2...v8.15.3">8.15.3</a> (2023-04-26)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/docs/docs_frontend/commit/99917257b5fd2f143a69d8944a6b1fddb79bd494">9991725</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/docs/docs_frontend/compare/v8.15.1...v8.15.2">8.15.2</a> (2023-04-24)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Schema:</strong> update to v6.1.0 (<a href="https://gitlab.com/mri-technologies/cosmic/docs/docs_frontend/commit/1973dc5f2b5cd58b0a5ee3f8f7c9917bdcb41033">1973dc5</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/docs/docs_frontend/compare/v8.15.0...v8.15.1">8.15.1</a> (2023-04-24)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/docs/docs_frontend/commit/322c2167202610aaffef70fb4253bc96d229c2b2">322c216</a>)</li>
</ul>
<h1><a href="https://gitlab.com/mri-technologies/cosmic/docs/docs_frontend/compare/v8.14.1...v8.15.0">8.15.0</a> (2023-04-18)</h1>
<h3>Features</h3>
<ul>
<li><strong>Schema:</strong> update to v6.0.0 (<a href="https://gitlab.com/mri-technologies/cosmic/docs/docs_frontend/commit/f5f0afb1a86dc1c35988b231b0b2a41de3a97f9e">f5f0afb</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/docs/docs_frontend/compare/v8.14.0...v8.14.1">8.14.1</a> (2023-04-18)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/docs/docs_frontend/commit/a70b7e516374eb930fdba5a8c7eeb1bbe4120107">a70b7e5</a>)</li>
</ul>
<h1><a href="https://gitlab.com/mri-technologies/cosmic/docs/docs_frontend/compare/v8.13.6...v8.14.0">8.14.0</a> (2023-04-18)</h1>
<h3>Features</h3>
<ul>
<li><strong>Schema:</strong> update to v5.1.1 (<a href="https://gitlab.com/mri-technologies/cosmic/docs/docs_frontend/commit/7db808d64f7a278dffdbd3e5f1fff5919c0f84f7">7db808d</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/docs/docs_frontend/compare/v8.13.5...v8.13.6">8.13.6</a> (2023-04-13)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/docs/docs_frontend/commit/033117bf81a97cf48abfb19213a89dd241b73f94">033117b</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/docs/docs_frontend/compare/v8.13.4...v8.13.5">8.13.5</a> (2023-04-12)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>FRACAS:</strong> Pass id instead of nodeId to location mini (<a href="https://gitlab.com/mri-technologies/cosmic/docs/docs_frontend/commit/ddf41fcff7075862f449221ac286415e03ff3f75">ddf41fc</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/docs/docs_frontend/compare/v8.13.3...v8.13.4">8.13.4</a> (2023-04-12)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Schema:</strong> update to v4.1.0 (<a href="https://gitlab.com/mri-technologies/cosmic/docs/docs_frontend/commit/f2b9566bc1261a84108dfb4207111541e8a5470a">f2b9566</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/docs/docs_frontend/compare/v8.13.2...v8.13.3">8.13.3</a> (2023-04-03)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Initialization Modal:</strong> Register minis for dynamic component (<a href="https://gitlab.com/mri-technologies/cosmic/docs/docs_frontend/commit/6efa044b8b083dec4511fec53ad8e2082e8c6107">6efa044</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/docs/docs_frontend/compare/v8.13.1...v8.13.2">8.13.2</a> (2023-03-31)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/docs/docs_frontend/commit/e7b0fa1b85796aef6e954888825c6e166572653c">e7b0fa1</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/docs/docs_frontend/compare/v8.13.0...v8.13.1">8.13.1</a> (2023-03-29)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Schema:</strong> update to v4.0.2 (<a href="https://gitlab.com/mri-technologies/cosmic/docs/docs_frontend/commit/678b55f8e01e24a18983e00190d39e9e4201cc57">678b55f</a>)</li>
</ul>
<h1><a href="https://gitlab.com/mri-technologies/cosmic/docs/docs_frontend/compare/v8.12.0...v8.13.0">8.13.0</a> (2023-03-29)</h1>
<h3>Features</h3>
<ul>
<li><strong>Workflows:</strong> update manage page. (<a href="https://gitlab.com/mri-technologies/cosmic/docs/docs_frontend/commit/7e5b080d0ae5eb435ae53efaa011dfe7de4c0629">7e5b080</a>)</li>
</ul>
<h1><a href="https://gitlab.com/mri-technologies/cosmic/docs/docs_frontend/compare/v8.11.5...v8.12.0">8.12.0</a> (2023-03-29)</h1>
<h3>Features</h3>
<ul>
<li><strong>RCA:</strong> update manage page. (<a href="https://gitlab.com/mri-technologies/cosmic/docs/docs_frontend/commit/6f301fd86f59431061347005d017f8671dbb9d6b">6f301fd</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/docs/docs_frontend/compare/v8.11.4...v8.11.5">8.11.5</a> (2023-03-28)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>EZT:</strong> update adds new header information. (<a href="https://gitlab.com/mri-technologies/cosmic/docs/docs_frontend/commit/dada088fc6286d5c9df02125ca106213a08b8c56">dada088</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/docs/docs_frontend/compare/v8.11.3...v8.11.4">8.11.4</a> (2023-03-28)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Fracas:</strong> update to new manage page layout (<a href="https://gitlab.com/mri-technologies/cosmic/docs/docs_frontend/commit/98dca43df248959e96542ba36003c8ffa8a2565b">98dca43</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/docs/docs_frontend/compare/v8.11.2...v8.11.3">8.11.3</a> (2023-03-28)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>DR:</strong> update manage page with new layout. (<a href="https://gitlab.com/mri-technologies/cosmic/docs/docs_frontend/commit/643047128c4e2a0656f3e09657f2bc61e6505e72">6430471</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/docs/docs_frontend/compare/v8.11.1...v8.11.2">8.11.2</a> (2023-03-28)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Tps:</strong> update adds new header information to the manage page (<a href="https://gitlab.com/mri-technologies/cosmic/docs/docs_frontend/commit/583a3ac06b0d492ec447077610d78ffe22b28d85">583a3ac</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/docs/docs_frontend/compare/v8.11.0...v8.11.1">8.11.1</a> (2023-03-27)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Schema:</strong> update to v4.0.1 (<a href="https://gitlab.com/mri-technologies/cosmic/docs/docs_frontend/commit/1497e5cbc19bb0ceafdd0fdb672b028a964c05a3">1497e5c</a>)</li>
</ul>
<h1><a href="https://gitlab.com/mri-technologies/cosmic/docs/docs_frontend/compare/v8.10.1...v8.11.0">8.11.0</a> (2023-03-27)</h1>
<h3>Features</h3>
<ul>
<li><strong>Schema:</strong> update to v4.0.0 (<a href="https://gitlab.com/mri-technologies/cosmic/docs/docs_frontend/commit/473ba9d31c14c2735536a4c4d4942ee763389f10">473ba9d</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/docs/docs_frontend/compare/v8.10.0...v8.10.1">8.10.1</a> (2023-03-24)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/docs/docs_frontend/commit/d6dd5ffaef87b787e41825a5fe4099dc378a21c2">d6dd5ff</a>)</li>
</ul>
<h1><a href="https://gitlab.com/mri-technologies/cosmic/docs/docs_frontend/compare/v8.9.0...v8.10.0">8.10.0</a> (2023-03-24)</h1>
<h3>Features</h3>
<ul>
<li><strong>Schema:</strong> update to v3.2.0 (<a href="https://gitlab.com/mri-technologies/cosmic/docs/docs_frontend/commit/b3314e4a5bf6a5124a64134755ab7032f74adbce">b3314e4</a>)</li>
</ul>
<h1><a href="https://gitlab.com/mri-technologies/cosmic/docs/docs_frontend/compare/v8.8.1...v8.9.0">8.9.0</a> (2023-03-23)</h1>
<h3>Features</h3>
<ul>
<li><strong>Schema:</strong> update to v3.1.0 (<a href="https://gitlab.com/mri-technologies/cosmic/docs/docs_frontend/commit/1a80d7df073c9f07d28b60b9f4e544be7b023a30">1a80d7d</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/docs/docs_frontend/compare/v8.8.0...v8.8.1">8.8.1</a> (2023-03-23)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/docs/docs_frontend/commit/ee11346bc4805110f385fa5f8536c8099965004f">ee11346</a>)</li>
</ul>
<h1><a href="https://gitlab.com/mri-technologies/cosmic/docs/docs_frontend/compare/v8.7.12...v8.8.0">8.8.0</a> (2023-03-22)</h1>
<h3>Features</h3>
<ul>
<li><strong>JF288:</strong> Add new spreadsheet template (<a href="https://gitlab.com/mri-technologies/cosmic/docs/docs_frontend/commit/5794b037d5dab7d43cb3fd37bc2757f92400f3d4">5794b03</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/docs/docs_frontend/compare/v8.7.11...v8.7.12">8.7.12</a> (2023-03-22)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Documents:</strong> update includes search improvements and color adjustments (<a href="https://gitlab.com/mri-technologies/cosmic/docs/docs_frontend/commit/fab470b600440608ecb215e44db7b8c42a83fcb5">fab470b</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/docs/docs_frontend/compare/v8.7.10...v8.7.11">8.7.11</a> (2023-03-21)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Schema:</strong> update to v3.0.7 (<a href="https://gitlab.com/mri-technologies/cosmic/docs/docs_frontend/commit/bba73c63879c4968d6b450653e70af244df8a9a3">bba73c6</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/docs/docs_frontend/compare/v8.7.9...v8.7.10">8.7.10</a> (2023-03-21)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/docs/docs_frontend/commit/67f981bcbdbfd7c260a82ca31d07ef439e9b0cc6">67f981b</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/docs/docs_frontend/compare/v8.7.8...v8.7.9">8.7.9</a> (2023-03-21)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Schema:</strong> update to v3.0.5 (<a href="https://gitlab.com/mri-technologies/cosmic/docs/docs_frontend/commit/322fa19802cb1d4ca28cdc63a6c4a22fe5813c62">322fa19</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/docs/docs_frontend/compare/v8.7.7...v8.7.8">8.7.8</a> (2023-03-21)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Schema:</strong> update to v3.0.4 (<a href="https://gitlab.com/mri-technologies/cosmic/docs/docs_frontend/commit/653221bdaa677397757cfec8ec8d38ec58e44aab">653221b</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/docs/docs_frontend/compare/v8.7.6...v8.7.7">8.7.7</a> (2023-03-21)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Schema:</strong> update to v3.0.3 (<a href="https://gitlab.com/mri-technologies/cosmic/docs/docs_frontend/commit/967a6a9c86faa030ce882748b4197b4334d3f3ef">967a6a9</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/docs/docs_frontend/compare/v8.7.5...v8.7.6">8.7.6</a> (2023-03-20)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Schema:</strong> update to v3.0.2 (<a href="https://gitlab.com/mri-technologies/cosmic/docs/docs_frontend/commit/74d665071ad79d5c80ffa7dc5eb590a18c0b4a5c">74d6650</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/docs/docs_frontend/compare/v8.7.4...v8.7.5">8.7.5</a> (2023-03-20)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Schema:</strong> update to v3.0.1 (<a href="https://gitlab.com/mri-technologies/cosmic/docs/docs_frontend/commit/b96afa1ea794463210ade0597ad924db879a9ecd">b96afa1</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/docs/docs_frontend/compare/v8.7.3...v8.7.4">8.7.4</a> (2023-03-17)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/docs/docs_frontend/commit/b9fdc17966d8c96b7aa57080783e52b3d1fcfaa8">b9fdc17</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/docs/docs_frontend/compare/v8.7.2...v8.7.3">8.7.3</a> (2023-03-16)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Optimize:</strong> Optimize build size (<a href="https://gitlab.com/mri-technologies/cosmic/docs/docs_frontend/commit/562305bbc50fed4df0f3f584305b5b44bf93ebd4">562305b</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/docs/docs_frontend/compare/v8.7.1...v8.7.2">8.7.2</a> (2023-03-16)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/docs/docs_frontend/commit/3c1ad2c53d7244a65a015d0713deb7d7be5ddc1b">3c1ad2c</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/docs/docs_frontend/compare/v8.7.0...v8.7.1">8.7.1</a> (2023-03-15)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/docs/docs_frontend/commit/cee73caf8a5dadbc96b6f8404c0af129d7b96c9b">cee73ca</a>)</li>
</ul>
<h1><a href="https://gitlab.com/mri-technologies/cosmic/docs/docs_frontend/compare/v8.6.3...v8.7.0">8.7.0</a> (2023-03-14)</h1>
<h3>Features</h3>
<ul>
<li><strong>Schema:</strong> update to v3.0.0 (<a href="https://gitlab.com/mri-technologies/cosmic/docs/docs_frontend/commit/f2eec1fa13bdf68b06b70e7aff35e046624efb7c">f2eec1f</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/docs/docs_frontend/compare/v8.6.2...v8.6.3">8.6.3</a> (2023-03-13)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Jf288:</strong> Adjust col widths in section 8 to make more room for notes (<a href="https://gitlab.com/mri-technologies/cosmic/docs/docs_frontend/commit/9578a11805c7f6b6f926ac563ec33b9b2452c804">9578a11</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/docs/docs_frontend/compare/v8.6.1...v8.6.2">8.6.2</a> (2023-03-13)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>JF288:</strong> Default monthly hazards open or closed to N/A if there is no value from the spreadsheet (<a href="https://gitlab.com/mri-technologies/cosmic/docs/docs_frontend/commit/30e6abd3b0b1ff05867bbddd9725c159bbcf3688">30e6abd</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/docs/docs_frontend/compare/v8.6.0...v8.6.1">8.6.1</a> (2023-03-13)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>JF288:</strong> Add number formatting to MRI and Geocontrols total hours (<a href="https://gitlab.com/mri-technologies/cosmic/docs/docs_frontend/commit/46de633229e3b9b60e103bc9254113417a02a336">46de633</a>)</li>
</ul>
<h1><a href="https://gitlab.com/mri-technologies/cosmic/docs/docs_frontend/compare/v8.5.1...v8.6.0">8.6.0</a> (2023-03-09)</h1>
<h3>Features</h3>
<ul>
<li><strong>JF288:</strong> Add defaults and validators on section 8 (<a href="https://gitlab.com/mri-technologies/cosmic/docs/docs_frontend/commit/e507074619708883bcba1fdf48720d3b731311a8">e507074</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/docs/docs_frontend/compare/v8.5.0...v8.5.1">8.5.1</a> (2023-03-09)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>JF288 Manage:</strong> Fix bug allowing month to be set to Jan from query string (<a href="https://gitlab.com/mri-technologies/cosmic/docs/docs_frontend/commit/a44748ccc898916d46b5f81af0803756a293792d">a44748c</a>)</li>
</ul>
<h1><a href="https://gitlab.com/mri-technologies/cosmic/docs/docs_frontend/compare/v8.4.0...v8.5.0">8.5.0</a> (2023-03-08)</h1>
<h3>Features</h3>
<ul>
<li><strong>JF288:</strong> Sort subcontractors by name on report (<a href="https://gitlab.com/mri-technologies/cosmic/docs/docs_frontend/commit/9adb753db826fb7dd689b1cad4d866b03c7b0178">9adb753</a>)</li>
</ul>
<h1><a href="https://gitlab.com/mri-technologies/cosmic/docs/docs_frontend/compare/v8.3.1...v8.4.0">8.4.0</a> (2023-03-08)</h1>
<h3>Features</h3>
<ul>
<li><strong>JF288:</strong> Add defaults and validations to section 7 (<a href="https://gitlab.com/mri-technologies/cosmic/docs/docs_frontend/commit/eacefa9b7f48f756886c70534547f72939bf5d40">eacefa9</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/docs/docs_frontend/compare/v8.3.0...v8.3.1">8.3.1</a> (2023-03-08)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Schema:</strong> update to v2.0.1 (<a href="https://gitlab.com/mri-technologies/cosmic/docs/docs_frontend/commit/494455b28e94ec5d192c07ba78addc01fb51e3d8">494455b</a>)</li>
</ul>
<h1><a href="https://gitlab.com/mri-technologies/cosmic/docs/docs_frontend/compare/v8.2.0...v8.3.0">8.3.0</a> (2023-03-07)</h1>
<h3>Features</h3>
<ul>
<li><strong>JF288:</strong> Add section 6 defaults and only allow yes/no for inspection flag (<a href="https://gitlab.com/mri-technologies/cosmic/docs/docs_frontend/commit/d1fb7cfefe1e86022276da9fb228d5af1731dc9b">d1fb7cf</a>)</li>
</ul>
<h1><a href="https://gitlab.com/mri-technologies/cosmic/docs/docs_frontend/compare/v8.1.1...v8.2.0">8.2.0</a> (2023-03-07)</h1>
<h3>Features</h3>
<ul>
<li><strong>JF288:</strong> Add standard event activities with purpose to section 5 (<a href="https://gitlab.com/mri-technologies/cosmic/docs/docs_frontend/commit/116309ead88d59cbaa9f2cd51836b8508ec776ae">116309e</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/docs/docs_frontend/compare/v8.1.0...v8.1.1">8.1.1</a> (2023-03-07)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/docs/docs_frontend/commit/7fa687209627f2526ef0439ded3f0a58350d44fc">7fa6872</a>)</li>
</ul>
<h1><a href="https://gitlab.com/mri-technologies/cosmic/docs/docs_frontend/compare/v8.0.11...v8.1.0">8.1.0</a> (2023-03-07)</h1>
<h3>Features</h3>
<ul>
<li><strong>Schema:</strong> update to v2.0.0 (<a href="https://gitlab.com/mri-technologies/cosmic/docs/docs_frontend/commit/8143d96da71ef0bc4c0c83bd51d10058baf8f06f">8143d96</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/docs/docs_frontend/compare/v8.0.10...v8.0.11">8.0.11</a> (2023-03-07)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/docs/docs_frontend/commit/cb820c0824cf09eae7e1ac5d6a2c87a6d710cdc5">cb820c0</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/docs/docs_frontend/compare/v8.0.9...v8.0.10">8.0.10</a> (2023-03-03)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/docs/docs_frontend/commit/86484e5deaba030618c6c20bef29d270512e871d">86484e5</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/docs/docs_frontend/compare/v8.0.8...v8.0.9">8.0.9</a> (2023-03-03)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/docs/docs_frontend/commit/11900b14330d6fca67755ead2912af02c9143288">11900b1</a>)</li>
<li><strong>Schema:</strong> update to v1.6.2 (<a href="https://gitlab.com/mri-technologies/cosmic/docs/docs_frontend/commit/8b927568de42a2f3918b419d0c1d6d778ac8ee68">8b92756</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/docs/docs_frontend/compare/v8.0.7...v8.0.8">8.0.8</a> (2023-03-02)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Schema:</strong> update to v1.6.1 (<a href="https://gitlab.com/mri-technologies/cosmic/docs/docs_frontend/commit/44d5772df1581bd806bb922d24e7a5c5f7650c9c">44d5772</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/docs/docs_frontend/compare/v8.0.6...v8.0.7">8.0.7</a> (2023-03-01)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Schema:</strong> update to v1.5.0 (<a href="https://gitlab.com/mri-technologies/cosmic/docs/docs_frontend/commit/88bc200086791e4de97a695ae59f21a7952abe69">88bc200</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/docs/docs_frontend/compare/v8.0.5...v8.0.6">8.0.6</a> (2023-02-23)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Schema:</strong> update to v1.4.1 (<a href="https://gitlab.com/mri-technologies/cosmic/docs/docs_frontend/commit/43fe2d275864c62814b3a319c99bf6fd34da9203">43fe2d2</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/docs/docs_frontend/compare/v8.0.4...v8.0.5">8.0.5</a> (2023-02-21)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Schema:</strong> update to v1.4.0 (<a href="https://gitlab.com/mri-technologies/cosmic/docs/docs_frontend/commit/d5a71600b25671c6cbb71f35d6bd1249a94ca5de">d5a7160</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/docs/docs_frontend/compare/v8.0.3...v8.0.4">8.0.4</a> (2023-02-17)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/docs/docs_frontend/commit/fac1b9f5f2cafc743f7c39dc8ff5c109232970c1">fac1b9f</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/docs/docs_frontend/compare/v8.0.2...v8.0.3">8.0.3</a> (2023-02-16)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/docs/docs_frontend/commit/651604ebe8fb86c0e8280f1ed51659eb6373bb17">651604e</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/docs/docs_frontend/compare/v8.0.1...v8.0.2">8.0.2</a> (2023-02-16)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Schema:</strong> update to v1.3.1 (<a href="https://gitlab.com/mri-technologies/cosmic/docs/docs_frontend/commit/f4425dd6b908397743347ab7c392bb02515486a5">f4425dd</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/docs/docs_frontend/compare/v8.0.0...v8.0.1">8.0.1</a> (2023-02-13)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Schema:</strong> update to v1.2.1 (<a href="https://gitlab.com/mri-technologies/cosmic/docs/docs_frontend/commit/a039c46b536b1525e5d3dcbfe7fa36033e9f68ff">a039c46</a>)</li>
</ul>
<h1><a href="https://gitlab.com/mri-technologies/cosmic/docs/docs_frontend/compare/v7.10.3...v8.0.0">8.0.0</a> (2023-02-10)</h1>
<h3>Features</h3>
<ul>
<li><strong>Schema:</strong> update to v1.2.0 (<a href="https://gitlab.com/mri-technologies/cosmic/docs/docs_frontend/commit/bc4ec6f6239dfd1d878dbbd2dbe6f8cb9802f0e6">bc4ec6f</a>)</li>
</ul>
<h3>BREAKING CHANGES</h3>
<ul>
<li><strong>Schema:</strong> moves away from graphql</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/docs/docs_frontend/compare/v7.10.2...v7.10.3">7.10.3</a> (2023-02-08)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/docs/docs_frontend/commit/3094461d2e76231fe211d7746b89f0df456a7531">3094461</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/docs/docs_frontend/compare/v7.10.1...v7.10.2">7.10.2</a> (2023-02-08)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Schema:</strong> update to v72.2.3 (<a href="https://gitlab.com/mri-technologies/cosmic/docs/docs_frontend/commit/04eb6e35ad641ce9800062cfde2622b3bcd05573">04eb6e3</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/docs/docs_frontend/compare/v7.10.0...v7.10.1">7.10.1</a> (2023-02-03)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/docs/docs_frontend/commit/09d9cce7f6d9e2867f194cfc28aa27c418ce71b5">09d9cce</a>)</li>
</ul>
<h1><a href="https://gitlab.com/mri-technologies/cosmic/docs/docs_frontend/compare/v7.9.6...v7.10.0">7.10.0</a> (2023-02-03)</h1>
<h3>Features</h3>
<ul>
<li><strong>Routing and Footer:</strong> update overhauls application routing; moves the change log to the side navigation (<a href="https://gitlab.com/mri-technologies/cosmic/docs/docs_frontend/commit/47ca5de02ababec6238030af9f826ca8f7966590">47ca5de</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/docs/docs_frontend/compare/v7.9.5...v7.9.6">7.9.6</a> (2023-01-25)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>schema:</strong> upgrade schema to 72.2.2 (<a href="https://gitlab.com/mri-technologies/cosmic/docs/docs_frontend/commit/061ab572d86c38c8752141ed15bb3261cefc4f43">061ab57</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/docs/docs_frontend/compare/v7.9.4...v7.9.5">7.9.5</a> (2023-01-23)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Schema:</strong> update to v72.2.1 (<a href="https://gitlab.com/mri-technologies/cosmic/docs/docs_frontend/commit/7898a4fc4bfd53951275000580948ab57d62c2ab">7898a4f</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/docs/docs_frontend/compare/v7.9.3...v7.9.4">7.9.4</a> (2023-01-20)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>schema:</strong> upgrade schema to 72.2.0 (<a href="https://gitlab.com/mri-technologies/cosmic/docs/docs_frontend/commit/8bba87002e5e75eabbabe6c4e3edf664956b4e7a">8bba870</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/docs/docs_frontend/compare/v7.9.2...v7.9.3">7.9.3</a> (2023-01-20)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>schema:</strong> upgrade schema to 72.1.4 (<a href="https://gitlab.com/mri-technologies/cosmic/docs/docs_frontend/commit/66e025bbd61fd3b8e674cfe799f975344f08e24d">66e025b</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/docs/docs_frontend/compare/v7.9.1...v7.9.2">7.9.2</a> (2023-01-19)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Schema:</strong> update to v72.1.3 (<a href="https://gitlab.com/mri-technologies/cosmic/docs/docs_frontend/commit/8f655132d28235a77c9ed2408955855877ad3b86">8f65513</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/docs/docs_frontend/compare/v7.9.0...v7.9.1">7.9.1</a> (2023-01-18)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> CSS Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/docs/docs_frontend/commit/2ca7ea22f869da6af220b3c7dcaa099e496fa16c">2ca7ea2</a>)</li>
</ul>
<h1><a href="https://gitlab.com/mri-technologies/cosmic/docs/docs_frontend/compare/v7.8.4...v7.9.0">7.9.0</a> (2023-01-17)</h1>
<h3>Features</h3>
<ul>
<li><strong>JF288:</strong> Replace jf288 template file (<a href="https://gitlab.com/mri-technologies/cosmic/docs/docs_frontend/commit/ae8dcc6285644cbbfbcff30fad9ac9fa5a8c70aa">ae8dcc6</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/docs/docs_frontend/compare/v7.8.3...v7.8.4">7.8.4</a> (2023-01-16)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>schema:</strong> upgrade schema to 72.1.1 (<a href="https://gitlab.com/mri-technologies/cosmic/docs/docs_frontend/commit/1994371fe039267b877167bceabad892b4de6b25">1994371</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/docs/docs_frontend/compare/v7.8.2...v7.8.3">7.8.3</a> (2023-01-16)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>schema:</strong> upgrade schema to 72.1.0 (<a href="https://gitlab.com/mri-technologies/cosmic/docs/docs_frontend/commit/6bddfff1d4ea57ba4956dabd5e91cc1bc9cdc058">6bddfff</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/docs/docs_frontend/compare/v7.8.1...v7.8.2">7.8.2</a> (2023-01-16)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Schema:</strong> update to v72.0.5 (<a href="https://gitlab.com/mri-technologies/cosmic/docs/docs_frontend/commit/78949e64c5d93e74319cbfb888cfadb2053e9c4f">78949e6</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/docs/docs_frontend/compare/v7.8.0...v7.8.1">7.8.1</a> (2023-01-13)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Schema:</strong> update to v72.0.4 (<a href="https://gitlab.com/mri-technologies/cosmic/docs/docs_frontend/commit/b9cba566108c9c05cd7389e65114710f050f31bf">b9cba56</a>)</li>
</ul>
<h1><a href="https://gitlab.com/mri-technologies/cosmic/docs/docs_frontend/compare/v7.7.1...v7.8.0">7.8.0</a> (2023-01-11)</h1>
<h3>Features</h3>
<ul>
<li><strong>Schema:</strong> update to v72.0.3 (<a href="https://gitlab.com/mri-technologies/cosmic/docs/docs_frontend/commit/54450a556d4ceacf0da4fb22d51e1b9c3b7199f5">54450a5</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/docs/docs_frontend/compare/v7.7.0...v7.7.1">7.7.1</a> (2023-01-06)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/docs/docs_frontend/commit/f3edb74fe7ea2cd825ea00b948dcd28167df397c">f3edb74</a>)</li>
</ul>
<h1><a href="https://gitlab.com/mri-technologies/cosmic/docs/docs_frontend/compare/v7.6.2...v7.7.0">7.7.0</a> (2023-01-06)</h1>
<h3>Features</h3>
<ul>
<li><strong>ErrorHandling:</strong> Maturing how we handle system errors. (<a href="https://gitlab.com/mri-technologies/cosmic/docs/docs_frontend/commit/bcb0353d7289f38c8afbb520618ed0f5741aeb44">bcb0353</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/docs/docs_frontend/compare/v7.6.1...v7.6.2">7.6.2</a> (2023-01-06)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Schema:</strong> update to v71.0.3 (<a href="https://gitlab.com/mri-technologies/cosmic/docs/docs_frontend/commit/04c2ae350b4b2eb6f6c0a8a91ccd580fd2dad746">04c2ae3</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/docs/docs_frontend/compare/v7.6.0...v7.6.1">7.6.1</a> (2023-01-04)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/docs/docs_frontend/commit/7f255833c4b6007d2dd36849464150355c371e73">7f25583</a>)</li>
</ul>
<h1><a href="https://gitlab.com/mri-technologies/cosmic/docs/docs_frontend/compare/v7.5.0...v7.6.0">7.6.0</a> (2023-01-04)</h1>
</section></template><script>export default {components:{}}</script>
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "the_nav_bar_container"
  }, [_vm._t("alerts"), _c('div', {
    staticClass: "the_nav_bar"
  }, [_c('div', {
    staticClass: "nav_bar_item"
  }, [_c('v-toolbar-side-icon', {
    staticClass: "menu_hamburger_icon",
    attrs: {
      "aria-label": "toggle menu",
      "aria-selected": "false",
      "tooltip": "Toggle menu",
      "tooltip-position": "bottom left"
    },
    on: {
      "click": function ($event) {
        $event.stopPropagation();
        return _vm.toggleTheSideNavigationOpenState.apply(null, arguments);
      },
      "mouseover": function ($event) {
        _vm.isHoveringOverHamburger = true;
      },
      "mouseleave": function ($event) {
        _vm.isHoveringOverHamburger = false;
      }
    }
  }, [!_vm.isHoveringOverHamburger ? _c('c-icon-fa', {
    attrs: {
      "icon": "fa fas fa-bars",
      "icon-color": "white",
      "icon-size": "20px"
    }
  }) : _c('c-icon-fa', {
    attrs: {
      "icon": "fa fas fa-bars",
      "icon-color": "black",
      "icon-size": "20px"
    }
  })], 1)], 1), _c('div', {
    staticClass: "nav_bar_item logo"
  }, [_c('v-toolbar-title', {
    staticClass: "application_title",
    attrs: {
      "tooltip": "Docs Home"
    }
  }, [_c('a', {
    staticClass: "delinkify",
    attrs: {
      "href": "/"
    },
    on: {
      "click": _vm.onClickOfCosmicLogo
    }
  }, [_c('img', {
    style: _vm.logoStyle,
    attrs: {
      "src": _vm.cosmicDocumentsLogoSrc,
      "alt": "COSMIC Docs"
    }
  })])])], 1), _vm.shouldShowNewBrowserWindowIcon ? _c('div', {
    ref: "new_tab_icon",
    staticClass: "nav_bar_item new_tab_icon",
    on: {
      "mouseover": function ($event) {
        _vm.isHoveringOverNewTab = true;
      },
      "mouseleave": function ($event) {
        _vm.isHoveringOverNewTab = false;
      }
    }
  }, [_c('a', {
    staticClass: "delinkify new_tab",
    attrs: {
      "aria-label": "open cosmic in a new tab",
      "href": "/",
      "target": "_blank",
      "tooltip": "Open COSMIC in a new tab"
    }
  }, [_c('div', [_c('c-icon-fa', {
    attrs: {
      "icon-color": _vm.isHoveringOverNewTab ? 'black' : 'white',
      "icon": "fad fa-browser",
      "icon-size": "20px"
    }
  }), _c('c-icon-fa', {
    staticClass: "browser-plus",
    attrs: {
      "icon-color": _vm.isHoveringOverNewTab ? 'black' : 'white',
      "icon": "fas fa-plus",
      "icon-size": "16px"
    }
  })], 1)])]) : _vm._e(), _c('v-spacer'), _vm.currentUserHasSupportContexts ? _c('div', {
    staticClass: "nav_bar_item"
  }, [_c('TheCreateMenu', {
    attrs: {
      "items": _vm.computedCreateMenuItemsBasedOnCtx
    }
  })], 1) : _vm._e(), _vm.shouldShowFavoritesLink ? _c('div', {
    ref: "favorites_link",
    staticClass: "nav_bar_item"
  }, [_c('button', {
    staticClass: "navbar_link",
    attrs: {
      "tooltip": "View your favorites"
    },
    on: {
      "click": function ($event) {
        _vm.showFavoritesModal = !_vm.showFavoritesModal;
      }
    }
  }, [_vm._v(" Favorites ")]), _vm.showFavoritesModal ? _c('AppModal', {
    attrs: {
      "hide-title-section": ""
    },
    on: {
      "modal-close": function ($event) {
        _vm.showFavoritesModal = false;
      },
      "keyup": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "escape", undefined, $event.key, undefined)) return null;
        _vm.showFavoritesModal = false;
      }
    }
  }, [_c('template', {
    slot: "content"
  }, [_c('UserFavorites', {
    attrs: {
      "hide-title": ""
    }
  })], 1), _c('template', {
    slot: "actions"
  }, [_c('ButtonLink', {
    attrs: {
      "aria-label": "user settings",
      "color": _vm.EntityType.USER,
      "dark": "",
      "to": _vm.usersManageLink
    },
    on: {
      "click": function ($event) {
        _vm.showFavoritesModal = false;
      }
    }
  }, [_c('c-icon-fa', {
    staticClass: "mr-2",
    attrs: {
      "icon": "fa fa-cog",
      "icon-color": "white"
    }
  }), _c('span', [_vm._v("User settings")])], 1), _c('v-spacer'), _c('ButtonCancel', {
    on: {
      "click": function ($event) {
        _vm.showFavoritesModal = false;
      }
    }
  }, [_vm._v("Close")])], 1)], 2) : _vm._e()], 1) : _vm._e(), _vm.currentUserHasSupportContexts && _vm.shouldShowMyApprovals ? _c('div', {
    ref: "my_approvals",
    staticClass: "nav_bar_item"
  }, [_c('MenuMyCdocsApprovals')], 1) : _vm._e(), _vm.currentUserHasSupportContexts && _vm.shouldShowUserSupport ? _c('div', {
    ref: "support_context",
    staticClass: "nav_bar_item"
  }, [_c('TheActiveUserSupportsDisplay', {
    staticClass: "nav_bar_item"
  })], 1) : _vm._e(), _c('div', {
    staticClass: "nav_bar_item my-1 mr-1 ml-3 avatar"
  }, [_c('TheUserAvatar')], 1), _c('TheSessionTimeout'), false ? _c('v-dialog', {
    attrs: {
      "persistent": "",
      "max-width": "400"
    }
  }, [_c('v-card', [_c('v-card-title', {
    staticClass: "headline"
  }, [_vm._v("Your NASA Launchpad session has expired.")]), _c('v-card-text', [_vm._v("Please reauthorize your session.")]), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "color": "success"
    },
    on: {
      "click": _vm.handleLaunchpadSessionAuthorizeClick
    }
  }, [_vm._v("Authorize")])], 1)], 1)], 1) : _vm._e()], 1), _c('TheSideNav', {
    ref: "TheSideNav",
    attrs: {
      "aria-selected": _vm.ariaSelected,
      "outage-alert-state": _vm.outageAlertState
    },
    on: {
      "closeTheSideNav": _vm.closeTheSideNav,
      "openSideNav": function ($event) {
        _vm.navOpenState = true;
      }
    },
    model: {
      value: _vm.navOpenState,
      callback: function ($$v) {
        _vm.navOpenState = $$v;
      },
      expression: "navOpenState"
    }
  })], 2);
};
var staticRenderFns = [];
export { render, staticRenderFns };
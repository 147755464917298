var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "home"
  }, [_vm.changeSetsNeedingApproval && _vm.changeSetsNeedingApproval.length > 0 ? _c('AppTableActions', {
    staticClass: "mb-3",
    attrs: {
      "color": _vm.EntityType.CHANGE_SET,
      "customPagination": _vm.customPagination,
      "headers": _vm.changeSetTableHeaders,
      "icon": _vm.$icons[_vm.EntityType.CHANGE_SET],
      "items": _vm.changeSetsNeedingApproval,
      "loading": !_vm.changeSetsQueryResults,
      "show-count": "",
      "sticky": "",
      "title": "Needing Initialization approval"
    },
    scopedSlots: _vm._u([{
      key: "actions",
      fn: function ({
        item
      }) {
        return [_c('ButtonMiniActionManage', {
          attrs: {
            "to": _vm.buildUrlForDocumentManageFromChangeSet(item)
          }
        })];
      }
    }], null, false, 1203735785)
  }) : _vm._e(), _c('div', [!_vm.isLoading ? _c('Doughnut', {
    staticClass: "mb-3",
    attrs: {
      "options": _vm.chartOptions,
      "data": _vm.chartData,
      "height": 130
    }
  }) : _vm._e(), _c('v-slide-y-transition', {
    attrs: {
      "hide-on-leave": ""
    }
  }, [_vm.activeDonutLabel ? _c('div', {
    staticClass: "text-xs-center mb-2"
  }, [_c('ButtonGeneric', {
    staticClass: "mr-2",
    attrs: {
      "icon": _vm.isLoading ? _vm.$icons.loading : _vm.$icons.clear,
      "color": "var(--cosmic-gray-lighten-4)",
      "text-color": "var(--cosmic-text)"
    },
    on: {
      "click": function ($event) {
        _vm.activeDonutLabel = null;
      }
    }
  }, [_c('span', [_vm._v("Clear filters")])])], 1) : _vm._e()]), _c('div', [_c('AppTableActions', {
    staticClass: "mb-3",
    attrs: {
      "color": _vm.EntityType.DOCUMENT,
      "customPagination": _vm.customPagination,
      "headers": _vm.computedCosmicManagedDocumentTableHeaders,
      "items": _vm.homeViewDocuments,
      "loading": _vm.isLoading,
      "title": _vm.computedTableTitle,
      "disable-initial-sort": "",
      "show-count": "",
      "show-search-input": "",
      "sticky": ""
    },
    scopedSlots: _vm._u([{
      key: "_stepOverview",
      fn: function ({
        item
      }) {
        return [_c('Bar', {
          staticClass: "my-1",
          attrs: {
            "options": _vm.barChartOptions,
            "data": _vm.getDocumentOverviewChartData(item),
            "height": 40
          }
        })];
      }
    }, {
      key: "actions",
      fn: function ({
        item
      }) {
        return [_c('ButtonMiniActionManage', {
          attrs: {
            "to": _vm.buildUrlForDocumentManage(item)
          }
        })];
      }
    }])
  })], 1)], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };
import { __decorate } from "tslib";
import { Component, Mixins } from 'vue-property-decorator';
import CurrentUserMixin from '~/nasa_ui/mixins/CurrentUserMixin';
import { AlertType, EntityType } from '~/nasa_ui/types';
import { EntityTypeMode } from '~/nasa_ui/types/enums/general';
let TheActiveUserSupportsDisplay = class TheActiveUserSupportsDisplay extends Mixins(CurrentUserMixin) {
    isReloading = false;
    EntityType = EntityType;
    EntityTypeMode = EntityTypeMode;
    get selectOptions() {
        return this.currentUserSupportContexts
            ? this.currentUserSupportContexts.filter((support) => {
                // Filter out the current selected ctx
                return support?.organizationCode !== this.currentUserActiveSupportContext;
            })
            : [];
    }
    get showCurrentUserSupoprtContext() {
        return Boolean(this.currentUserActiveSupportContext);
    }
    get currentUserSupportContextCount() {
        return this.currentUserSupportContexts?.length || 0;
    }
    get showUserSupportContextList() {
        return this.currentUserSupportContextCount > 1;
    }
    get hasOnlyOneContext() {
        return this.currentUserSupportContextCount === 1;
    }
    handleUserSupportSelection(support) {
        // save current user support context and reload
        this.setCurrentUserSupport(support);
    }
    setCurrentUserSupport(organizationCode) {
        // set current user support context and reload window
        this.setCurrentUserActiveSupportContext(organizationCode);
        this.$notification.add({
            type: AlertType.SUCCESS,
            text: 'User Support Context Updated. Reloading now.'
        });
        this.isReloading = true;
        // reload page
        setTimeout(() => {
            this.$router
                .replace({
                query: { ...this.$route.query, ctx: undefined }
            })
                .then(() => location.reload());
        }, 1500);
    }
};
TheActiveUserSupportsDisplay = __decorate([
    Component
], TheActiveUserSupportsDisplay);
export default TheActiveUserSupportsDisplay;
